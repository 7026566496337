
import { ReduceAll } from "./ReduceAll";
import { reverseCoordinates } from "../../utils/Normalizers";
import { PARK_ACTIONS } from "./Park";

export const MAP_ACTIONS = {
	LEAFLET_LOADED:       "LEAFLET_LOADED",
	CLICK_MARKER:         "CLICK_MARKER",
	SET_MAP_CONFIG:       "SET_MAP_CONFIG",
	ZOOM_MAP:"ZOOM_MAP",
	SET_MAP_MARKERS:      "SET_MAP_MARKERS",
	PARK_LEAFLET_REFRESH: "PARK_LEAFLET_REFRESH",

	SET_MAP_CENTER:"SET_MAP_CENTER",
	SET_ARRAY_CENTER:"SET_ARRAY_CENTER",
};

export function setMapConfig(config){
	return (dispatch) => {
		return dispatch({
			type: MAP_ACTIONS.SET_MAP_CONFIG,
			payload: {
				...config,
			},
		});
	}
}

export function setMapZoon(zoom){
	return (dispatch) => {
		return dispatch({
			type:MAP_ACTIONS.ZOOM_MAP,
			payload:{ zoom },
		});
	}
}
export function setMapCenter(center){
	return (dispatch) => {
		return dispatch({
			type:MAP_ACTIONS.SET_MAP_CENTER,
			payload:center,
		});
	}
}

export function setArrayCenter(center){
	return (dispatch) => {
		return dispatch({
			type:MAP_ACTIONS.SET_ARRAY_CENTER,
			payload:center,
		});
	}
}

export function loadMarkers(markers){
	return (dispatch) => {
		return dispatch({
			type:    MAP_ACTIONS.SET_MAP_MARKERS,
			payload: markers,
		});
	};
}

export function refreshLeaflet(){
	return (dispatch) => {
		return dispatch({ type:"PARK_LEAFLET_REFRESH", payload: false });
	};
}

export const IT = {
	isLeafletLoaded: false,
	showWaypoint:    true,
	layer:           "Stamen Terrain",
	center:          [39.833333, -98.583333],
	zoom:            4,
	geoJSON:         null,
	selectedMarker:  {},
	markers:         [],
};
const handlers = {
	[MAP_ACTIONS.LEAFLET_LOADED]:       initLeaflet,
	[MAP_ACTIONS.CLICK_MARKER]:         clickMark,
	[MAP_ACTIONS.SET_MAP_CONFIG]:       initConfig,
	[MAP_ACTIONS.SET_MAP_MARKERS]:      addMarkers,
	[MAP_ACTIONS.ZOOM_MAP]:updateZoom,
	[MAP_ACTIONS.PARK_LEAFLET_REFRESH]: refreshLeafletMaps,

	[MAP_ACTIONS.SET_MAP_CENTER]:initCenter,
	[PARK_ACTIONS.LOAD_PARK_DETAILS_SUCCESS]:setParkCenter,
	[PARK_ACTIONS.LOAD_PARK_CACHED]:setParkCenter,
	[MAP_ACTIONS.SET_ARRAY_CENTER]:initCenterArray,
};

function initCenterArray(state, action){
	var center = action.payload
		, newCenter = state.center
		;

	if(center && center[0] && center[1]){
		newCenter = center;
	}
	return {
		...state,
		center:newCenter,
	};
}

function initCenter(state, action){
	return {
		...state,
		center:[action.payload.center],
	};
}

function setParkCenter(state, action){
	return {
		...state,
		center:reverseCoordinates(action.payload.coordinates),
	};
}

function initLeaflet(state, action){
	return {
		...state,
		isLeafletLoaded: action.isLeafletLoaded,
	};
}

function clickMark(state, action){
	return {
		...state,
		selectedMarker: action.payload,
	};
}

function initConfig(state, action){
	return {
		...state,
		...action.payload
	};
}

function addMarkers(state, action){
	return {
		...state,
		markers: action.payload,
	};
}

function updateZoom(state, action){
	return {
		...state,
		zoom:action.payload.zoom,
	};
}

function refreshLeafletMaps(state, action){
	return {
		...state,
		showWaypoint: action.payload,
	};
}

export default ReduceAll(IT, handlers);

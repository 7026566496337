/* eslint-disable */
const TRACKED = { tracked: true }
	, NOT_FOUND = { tracked:false, error:{ message:"_paq not found"} }
	, SHOW_TRACKING = !(/prod/gi.test(process.env.NODE_ENV))
	;
export function trackPage(url, title = null){
	if(SHOW_TRACKING){
		console.groupCollapsed("Track Page");
			console.info("Page View");
			console.table({ url, title });
		console.groupEnd();
	}
	try {
		if(!_paq){ return Promise.reject(NOT_FOUND); }
		if(title){
			_paq.push(["setDocumentTitle", title])
		}
		_paq.push(["trackPageView"]);
	} catch(err){
		return Promise.reject({ tracked:false, error:err });
	}

	return Promise.resolve(TRACKED);
}

export function trackTime(time, info = null){
	if(SHOW_TRACKING){
		console.groupCollapsed("Track Timing");
			console.table({ time, info });
		console.groupEnd();
	}
	try {
		if(!_paq){ return Promise.reject(NOT_FOUND); }
		_paq.push(["setGenerationTimeMs", time]);

		if(info){
			_paq.push(["trackEvent", "Timing", info, null, time]);
		}
	} catch(err){
		return Promise.reject({ tracked:false, error:err });
	}

	return Promise.resolve(TRACKED);
}

export function trackEvent(cat, action, name = null, value = null){
	if(SHOW_TRACKING){
		console.groupCollapsed("Track Event");
			console.table({ cat, action, name, value });
		console.groupEnd();
	}
	try {
		if(!_paq){ return Promise.reject(NOT_FOUND); }
		_paq.push(["trackEvent", cat, action, name, value]);
	} catch (err){
		return Promise.reject({ tracked:false, error:err });	
	}

	return Promise.resolve(TRACKED);
}
/* eslint-enable */
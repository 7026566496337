import { ReduceAll } from "./ReduceAll";

export const PARK_ACTIONS = {
	LOAD_PARK_DETAILS_AJAX:"LOAD_PARK_DETAILS_AJAX",
	LOAD_PARK_DETAILS_SUCCESS:"LOAD_PARK_DETAILS_SUCCESS",
	LOAD_PARK_CACHED:"LOAD_PARK_CACHED",
	LOAD_PARK_DETAILS_ERROR:"LOAD_PARK_DETAILS_ERROR",

	LOAD_GEOJSON_AJAX:"LOAD_GEOJSON_AJAX",
	LOAD_GEOJSON_SUCCESS:"LOAD_GEOJSON_SUCCESS",
	LOAD_GEOJSON_ERROR:"LOAD_GEOJSON_ERROR",

	SELECT_VISIT_DAY:"SELECT_VISIT_DAY",
	SELECT_360:"SELECT_360",
};

export function loadPark(park){
	return (dispatch, getState) => {
		var currentPark = getState().park;

		if(currentPark.name && currentPark.name === park){
			dispatch({ type: PARK_ACTIONS.LOAD_PARK_CACHED, payload: currentPark });
			return Promise.resolve({ success:true });
		}

		return dispatch({
			type:PARK_ACTIONS.LOAD_PARK_DETAILS_AJAX,
			meta:{
				AJAX:{
					success:PARK_ACTIONS.LOAD_PARK_DETAILS_SUCCESS,
					url:"nps.php",
					params:{
						type:"park",
						name:park,
					},
				},
			},
		});
	};
}

export function loadGEO(parkName, type = "Tracks"){
	if(!parkName) { return; }
	return (dispatch, getState) => {
		var currentPark = getState().park;

		if(currentPark.name && currentPark.name === parkName){
			return;
		}
		return dispatch({
			type:  PARK_ACTIONS.LOAD_GEOJSON_AJAX,
			extra: { parkName },
			meta:{
				AJAX:  {
					url:     `/geojson/${parkName.toLowerCase().replace(/\s/gi,"-")}/${type}.geojson`,
					success: PARK_ACTIONS.LOAD_GEOJSON_SUCCESS,
					error:   PARK_ACTIONS.LOAD_GEOJSON_ERROR,
				},
			},
		});
	};
}

export function selectDate(index){
	return (dispatch) => {
		return dispatch({
			type:PARK_ACTIONS.SELECT_VISIT_DAY,
			payload:index,
		});
	};
}

export function select360Photo(index){
	return (dispatch) => {
		return dispatch({
			type:PARK_ACTIONS.SELECT_360,
			payload:index,
		});
	};
}

export const IT = {
	name:"",
	id:-1,
	activities:[],
	my_details:[],
	my_visits:[],
	visits:[],
	isParkLoading:false,
	date_established:"",
	area:0,
	geojson:null,
	currentVisitDay:0,
	selected360:-1,
};

const handlers = {
	[PARK_ACTIONS.LOAD_PARK_DETAILS_AJAX]:setParkLoading,
	[PARK_ACTIONS.LOAD_PARK_DETAILS_SUCCESS]:initLoadPark,
	[PARK_ACTIONS.LOAD_PARK_CACHED]:initLoadPark,

	[PARK_ACTIONS.LOAD_GEOJSON_SUCCESS]:initGEO,
	[PARK_ACTIONS.SELECT_VISIT_DAY]:setCurrentVisit,
	[PARK_ACTIONS.SELECT_360]:setCurrent360,
};

function setParkLoading(state, action){
	return {
		...state,
		isParkLoading:true,
	};
}

function initLoadPark(state, action){
	return {
		...state,
		...action.payload,
		selected360:0,
		currentVisitDay:0,
		isParkLoading:false,
	};
}

function initGEO(state, action){
	return {
		...state,
		geoJSON: action.payload,
	};
}

function setCurrentVisit(state, action){
	return {
		...state,
		currentVisitDay:action.payload,
	};
}

function setCurrent360(state, action){
	return {
		...state,
		selected360:action.payload,
	};
}

export default ReduceAll(IT, handlers);

import React from "react";
import { generateActivitySchema } from "../utils/common";

const ACT_URL = "//static.alphavega.com/images/svgs/icons/";

export default function Activity(props){
	var actProps = {
		className:"activity-2",
		"data-activity-name":props.name,
		itemType:generateActivitySchema(props.name),
	};

	if(props.isHighlighted){
		actProps.className += " done-today";
	}

	// let mask = `url(${ACT_URL}${props.icon}) no-repeat center`;
	return (
		<div itemScope {...actProps}>
			{/*<div className="activity-icon icon" style={{ "webkitMask":mask, mask, }} />*/}
			<img title={props.name} className="icon" src={`${ACT_URL}${props.icon}`} alt={props.name} itemProp="image" />
			<span className="activity-name" itemProp="name" arai-label={props.name}>{props.name}</span>
		</div>
	);
}

Activity.defaultProps = {
	isHighlighted:false,
	name:"",
	icon:"",
};
import React from 'react';
import PropTypes from "prop-types";

export default class Micro extends React.Component {

	static defaultProps = {
		micro:[]
	};

	static propTypes = {
		micro: PropTypes.arrayOf(PropTypes.shape({
			itemProp: PropTypes.string,
			content:  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			itemType: PropTypes.string,
			child:    PropTypes.array
		})).isRequired
	};

	render(){
		var props = this.props;

		return (
			<div className="hidden micro-data-holder" aria-hidden={true}>
				{props.micro.map(v => <MicroData {...v} key={v.key || v.itemProp || v.itemType} />)}
			</div>
		);
	}
}	//end Micro {}


export class MicroData extends React.Component {

	static defaultProps = {
		itemProp: "",
		content:  "",
		child:    null
	};

	render(){
		var { child, ...props } = this.props;

		if(child){
			return (
				<span itemScope
					itemProp={props.itemProp}
					itemType={props.itemType}>
					{child.map(v => <MicroData {...v} key={`IS-${v.itemProp}`} />)}
				</span>
			);
		} else {
			return <meta {...props} />;
		}
	}
}	//end MicroData {}
import { ReduceAll } from "./ReduceAll";
import shuffle from "../../utils/shuffle";

export const NAV_ACTIONS = {
	LOAD_NAV_AJAX:"LOAD_NAV_AJAX",
	LOAD_NAV_SUCCESS:"LOAD_NAV_SUCCESS",

	SHOW_NAV:"SHOW_NAV",
	HIDE_NAV:"HIDE_NAV",
	SHOW_EXPANDED:"SHOW_EXPANDED",
	HIDE_EXPANDED:"HIDE_EXPANDED",
};

export function shouldLoadNav(){
	return (dispatch, getState) => {
		var currentState = getState()
			, currentNav = currentState.nav
			;

		if(!currentNav){ return dispatch(loadNav()); }

		if(!(currentNav.info.albums || currentNav.info.parks || currentNav.info.states)){
			return dispatch(loadNav());
		}

		return Promise.resolve();
	};
}


export function loadNav(){
	return (dispatch) => {
		return dispatch({
			type:NAV_ACTIONS.LOAD_NAV_AJAX,
			meta:{
				AJAX:{
					success:NAV_ACTIONS.LOAD_NAV_SUCCESS,
					url:"info.php",
					params:{
						type:"nav",
					},
				},
			},
		});
	};
}

export function toggleNav(show){
	return {
		type: show ? NAV_ACTIONS.SHOW_NAV : NAV_ACTIONS.HIDE_NAV,
		meta: {
			track: {
				eventAction:   "Open Navigation",
				eventCategory: "Navigation",
				eventLabel:    show ? NAV_ACTIONS.SHOW_NAV : NAV_ACTIONS.HIDE_NAV,
			}
		}
	};
}

export function toggleExpanded(show = false, type = "albums"){
	return {
		type: show ? NAV_ACTIONS.SHOW_EXPANDED : NAV_ACTIONS.HIDE_EXPANDED,
		payload: type
	};
}

const IT = {
	isNavLoaded:false,
	isPhotosLoaded:false,
	info:{},
};

const handlers = {
	[NAV_ACTIONS.LOAD_NAV_AJAX]:setParkLoading,
	[NAV_ACTIONS.LOAD_NAV_SUCCESS]:initNav,

	[NAV_ACTIONS.SHOW_NAV]:showHideNav,
	[NAV_ACTIONS.HIDE_NAV]:showHideNav,
	[NAV_ACTIONS.SHOW_EXPANDED]:showHideExpand,
	[NAV_ACTIONS.HIDE_EXPANDED]:showHideExpand,
};

function setParkLoading(state, action){
	return {
		...state,
		isNavLoaded:false,
	};
}

const NPS = {
	name:"National Parks",
	icon:"",
	desc:"There are currently 61 National Parks, I've been to many of them.  If you want to cut to the chase and see the photos from those, check them out here.",
}
, STATES = {
	name:"The States of America",
	icon:"",
	desc:"Have you been to all 50 states?  I haven't yet, but I've got photo proof of a bunch of them!",
}
, ALBUMS = {
	name:"Loose Albums",
	icon:"",
	desc:"Individual albums, they cover everything from Air Shows, to nature, to Cities.  There's something for everyone here, take a look.",
}
;

function initNav(state, action){
	return {
		...state,
		info:{
			parks:_initGroup(NPS, action.payload.nps_nav),
			albums:_initGroup(ALBUMS, action.payload.nav),
			states:_initGroup(STATES, action.payload.states_nav),
		},
		isNavLoaded:true,
	};
}

function _initGroup(props, data){
	return {
		name:props.name,
		icon:props.icon,
		desc:props.desc,
		data:shuffle(data),
	};
}

function showHideNav(state, action){
	return {
		...state,
		isNavOpen:(action.type === NAV_ACTIONS.SHOW_NAV),
	};
}

function showHideExpand(state, action){
	return {
		...state,
		info:{
			...state.info,
			[action.payload]:{
				...state.info[action.payload],
				isExpanded:(action.type === NAV_ACTIONS.SHOW_EXPANDED),
			}
		},
	};
}

export default ReduceAll(IT, handlers);

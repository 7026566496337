/**
 * Usage File
 * This is the Information for the Usage section of the site
 * It contains the basic Usage information for the Photos
 */
import React		from 'react';
import Helmet		from 'react-helmet';
import axios from 'axios';

import CCLicense from "../footer/CCLicense";
export default class Usage extends React.Component {

	submitForm(e){
		var me = this;

		e.preventDefault();
		axios({
			method: 'post',
			url: '/ask/question/',
			data: {
				name:  me.refs.name.value,
				email: me.refs.email.value,
				body:  me.refs.body.value,
			}
		}).then(resp => {
			console.log("email sent", resp);
		}).catch(err => {
			console.log("something bad happened", err);
		});
	}

	render(){
		return(
			<div className="usage-holder">
				<Helmet>
					<title>Photo Usage Rights</title>
					<meta name="description" content="Learn about how to use these photos" />
					<meta property="og:title" content="Photo Usage Rights" />
					<meta property="og:type" content="website" />
					<meta property="og:url" content="https://steve.deadlycomputer.com/use/" />
				</Helmet>
				<Helmet title="Usage Rights" />
				<h1>Photo Usage Rights</h1>
				<article className="photo-usage">
					<section>
						<p>These photos are licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License</a>.  Basically, you are free to use them and display them, however you cannot alter them, provided you send a link back my way, and it is for a non-commercial purpose.</p>
						<p>Please use the contact form below if you would like to use these photos for any commercial use, of if you would like to inquire about purchasing a print.</p>
					</section>
					<CCLicense />
				</article>
				<section className="contact-form">
					<form onSubmit={() => this.submitForm()}>
						<div className="input-field">
							<input type="text" id="name" required ref="name" />
							<label htmlFor="name">Your Name</label>
						</div>
						<div className="input-field">
							<input type="email" id="email" required ref="email"/>
							<label htmlFor="email">Your Email</label>
						</div>
						<div className="input-field">
							<textarea id="body" className="materialize-textarea" required ref="body"></textarea>
							<label htmlFor="body">Textarea</label>
						</div>
						{/*<div className="input-field">
							<p>
								<label>Subject</label>
							</p>
							<ul>
								<li>
									<input type="radio" value="General" id="general" />
									<label htmlFor="general">General</label>
								</li>
								<li>
									<input type="radio" value="Buy" id="Buy" />
									<label htmlFor="Buy">Buy a Print</label>
								</li>
								<li>
									<input type="radio" value="Commercial" id="Commercial" />
									<label htmlFor="Commercial">Usae Commercially</label>
								</li>
							</ul>
						</div>*/}
						<div className="input-field">
							<button type="submit" className="btn">Send Message</button>
						</div>
					</form>
				</section>
			</div>
		);
	}
};

import { ReduceAll } from "./ReduceAll";

export const IT = {
	total_photos:      0,
	total_albums:      0,
	total_collections: 0,
	total_parks:0
}

const handlers = {};

export default ReduceAll(IT, handlers);

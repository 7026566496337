import React from "react";
import { connect } from "react-redux";

import { loadHomeAlbums } from "../store/reducers/Home";

import AlbumItem from "../collection/AlbumItem";
import CollectionItem from "../collection/CollectionItem";

export class Home extends React.Component {

	static displayName = "Home";

	componentWillMount(){
		this.props.loadHomeAlbums();
	}
	
	render(){
		return (
			<article className="the-list">
				{this.props.albums.map(v => {
					let albumName = v.name || v.long_name;
					if(v.type === "album"){
						return <AlbumItem key={v.id} {...v} name={albumName} />;	
					}
					return <CollectionItem key={`${v.id}-collection`} {...v} name={albumName} />;
				})}
			</article>
		);
	}

}

function mapStateToProps(state, ownProps){
	return {
		albums:state.home.albums,
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		loadHomeAlbums(){
			return dispatch(loadHomeAlbums());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
import React from "react";
import { Link } from "react-router-dom";

import Micro from "../utils/Micro";
import { Author, License } from "../utils/common";

import "../utils/ImgCaption.css";
import "./AlbumItem.css";

export default class CollectionItem extends React.Component {

	static displayName = "CollectionItem";

	static defaultProps = {
		name:"",
		desc:"",
		total_photos:0,
	}

	render(){
		var { props } = this
			, micro = [Author
				, License
				, { itemProp:"name", content:props.name }
				, { itemProp:"url", content:`https://steve.deadlycomputer.com/album/${props.name}/` }
			]
			;

		return (
			<section className="collection-item" itemScope itemType="https://schema.org/CreativeWork">
				<header>
					<h4 className="collection-name swish-title">
						<Link to={`/collection/${props.name}/`} className="title">{props.name}</Link>
					</h4>
					<p itemProp="description">{props.desc}</p>
				</header>
				<p className="total-albums"><strong>Total Albums:</strong>{props.total_photos}</p>
				<Micro micro={micro} />
			</section>
		);
	}
}

import { ReduceAll } from "./ReduceAll";
// import { format, parse } from "date-fns";

// import { DIMS_URL } from "../../utils/common";

export const HOME_ACTIONS = {
	LOAD_HOME_ALBUMS_AJAX:"LOAD_HOME_ALBUMS_AJAX",
	LOAD_HOME_ALBUMS_SUCCESS:"LOAD_HOME_ALBUMS_SUCCESS",
};

export function loadHomeAlbums(){
	return (dispatch, getState) => {
		var currentPhotos = getState().home;

		if(currentPhotos.isAlbumListLoaded){ return; }
		return dispatch({
			type:HOME_ACTIONS.LOAD_HOME_ALBUMS_AJAX,
			meta:{
				AJAX:{
					success:HOME_ACTIONS.LOAD_HOME_ALBUMS_SUCCESS,
					url:"info.php",
					params:{
						type:"home",
					},
				},
			},
		});
	};
}

export const IT = {
	isAlbumListLoaded:false,
	albums:[],
};

const handlers = {
	[HOME_ACTIONS.LOAD_HOME_ALBUMS_AJAX]:setIsLoading,
	[HOME_ACTIONS.LOAD_HOME_ALBUMS_SUCCESS]:initAlbums,
};

function setIsLoading(state, action){
	return {
		...state,
		isAlbumListLoaded:false,
	};
}

function initAlbums(state, action){
	return {
		...state,
		albums:action.payload,
		isAlbumListLoaded:true,
	};
}


export default ReduceAll(IT, handlers);

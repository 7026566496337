import { format, parse } from "date-fns";
import { DIMS_URL } from "utils/common";

export function reverseCoordinates(coords){
	var position = [];
	try{
		position = Object.assign([], coords.split(","));
	} catch (error) {
		console.error("Problem splitting");
		console.log(coords);
	}

	if(position.length > 2){
		position.pop();
	}
	return position.reverse().map(v => parseFloat(v));
}

const TIME_URL = "https://steve.deadlycomputer.com/dims/time/";

export function buildTimeURL(fileName, type = "video"){
	var name = type === "video" ? fileName : `p/${fileName.replace(/mp4/gi,"jpg")}`;

	return `${TIME_URL}${name}`;
}

export function parsePhotos(photo){
	var oo = Object.assign({}, photo);

	oo.taken_on = format(parse(photo.taken), "MMM DD, YYYY");
	oo.src = `${DIMS_URL}large/${oo.file_name}`;
	oo.alt = photo.name;
	oo.caption = oo.desc;

	return oo;
}
import React from "react";
import { connect } from "react-redux";

import { loadAlbum, loadPhotos } from "../store/reducers/Album";

import Header from "./Header";
import PhotoGallery from "./PhotoGallery";

export class Album extends React.Component {

	static displayName = "Album";

	componentWillMount(){
		var { props } = this;

		props.loadAlbum(props.match.params.album);
	}

	componentDidUpdate(prevProps){
		var currentProps = this.props;

		if(prevProps.match.params.album !== currentProps.match.params.album){
			currentProps.loadAlbum(currentProps.match.params.album);
		}
	}

	render(){
		return (
			<article className="the-album">
				<Header type="album" />
				<PhotoGallery photoList={this.props.photoList} randomPhoto={this.props.randomPhoto} />
			</article>
		);
	}

}

function mapStateToProps(state, ownProps){
	return {
		photoList:state.album.photos,
		randomPhoto:state.album.randomPhoto,
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		loadAlbum(albumName){
			dispatch(loadAlbum(albumName));
			return dispatch(loadPhotos(albumName));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Album);
import { ReduceAll } from "./ReduceAll";

// import { parsePhotos } from "utils/Normalizers";

export const PHOTO_ACTIONS = {
	LOAD_PHOTO_AJAX:"LOAD_PHOTO_AJAX",
	LOAD_PHOTO_SUCCESS:"LOAD_PHOTO_SUCCESS",

	LOAD_PARK_DETAILS_ERROR:"LOAD_PARK_DETAILS_ERROR",

	LOAD_PHOTOS_AJAX:"LOAD_PHOTOS_AJAX",
	LOAD_PHOTOS_SUCCESS:"LOAD_PHOTOS_SUCCESS",
};

export function loadPhotoInfo(photo){
	return (dispatch, getState) => {
		var currentPhoto = getState().photo.currentPhoto;

		if(currentPhoto.info){
			let { info } = currentPhoto;
			if(info.id === parseInt(photo)
				|| info.name.toLowerCase() === photo.toLowerCase()
				|| info.file_name.toLowerCase() === photo.toLowerCase()){
				console.log("No photos to load!");
				return;
			}
		}

		return dispatch({
			type:PHOTO_ACTIONS.LOAD_PHOTO_AJAX,
			meta:{
				AJAX:{
					success:PHOTO_ACTIONS.LOAD_PHOTO_SUCCESS,
					url:"info.php",
					params:{
						type:"photos",
						name:photo,
					},
				},
			},
		});
	};
}

export function loadExif(photo){
	return (dispatch) => {
		return dispatch({
			type:PHOTO_ACTIONS.LOAD_EXIF_AJAX,
			meta:{
				AJAX:{
					success:PHOTO_ACTIONS.LOAD_EXIF_SUCCESS,
					url:"info.php",
					params:{
						type:"photos",
						name:photo,
						what:"exif",
					},
				},
			},
		});
	};
}


export const IT = {
	isPhotoLoaded:false,
	photos:[],
	currentPhoto:{},
};

const handlers = {
	[PHOTO_ACTIONS.LOAD_PHOTO_AJAX]:setParkLoading,
	[PHOTO_ACTIONS.LOAD_PHOTO_SUCCESS]:initPhotoAPI,
};

function setParkLoading(state, action){
	return {
		...state,
		isPhotoLoaded:false,
	};
}

function initPhotoAPI(state, action){
	// console.log("The photos are:", action);
	return {
		isPhotoLoaded:true,
		// photos:state.photo.photos.concat(action.payload),
		currentPhoto:action.payload,
	};
}


export default ReduceAll(IT, handlers);

import React from "react";

import Micro from "../utils/Micro";

export default function State(props){
	var $icons = []
		, meta = []
		;

	props.states.forEach(v => {
		$icons.push(<i key={v.id} className="state-icon" title={v.name}>{v.face_letter}</i>);
		meta.push({
			itemProp:"place",
			itemType:"https://schema.org/Place",
			child:[{
				itemProp:"name",
				content:v.name,
			},{
				itemProp:"alternateName",
				content:v.abbreviation,
			}],
		});
	});

	return (
		<div className="state-list" itemScope itemType="containedInPlace">
			{$icons}
			<Micro micro={meta} />
		</div>
	);
}

import React from "react";
import { connect } from 'react-redux';

import { toggleExpanded } from "../store/reducers/Nav";
import NavItem from "./NavItem";
import "./NavGroup.css";

export class NavGroup extends React.Component {

	static defaultProps = {
		isExpanded: false,
		data:       [],
		name:       "",
		desc:       "Awesome Name dude",
	};

	_renderItems(){
		if(!this.props.isExpanded){ return null; }

		return this.props.data.map(v => <NavItem {...v} key={v.id} />);
	}

	render(){
		var me = this
			, props = me.props
			, expanded = props.isExpanded ? "is-expanded" : ""
			, title = props.isExpanded ? "Expand Album list" : ""
			;

		return (
			<section
				id={`collection_of_${props.type}`}
				className = {`nav-group ${expanded}`}
				title     = {title}
				onClick   = {() => this.props.toggleExpanded(me.props.isExpanded)}>
				<h3>{props.name}</h3>
				<p>{props.desc}</p>
				<strong>Total Items:</strong><span>{props.data.length}</span>
				<div className="album-items">
					{this._renderItems()}
				</div>
			</section>
		);
	}
}

function mapStateToProps(state, ownProps){
	return {
		...state.nav.info[ownProps.type]
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		toggleExpanded(show){
			return dispatch(toggleExpanded(!show, ownProps.type));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NavGroup);

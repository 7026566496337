
const LAYERS = {
	'Open Topo': {
		 url:         'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
		 maxZoom:     17,
		 attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
	},
	'Stamen Terrain': {
		url:         'https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.{ext}',
		attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
		subdomains:  'abcd',
		minZoom:     0,
		maxZoom:     18,
		ext:         'png',
	}
};

export default LAYERS;
import React from "react";

export default function CCLicense(props){
	return (
		<section className="cc-license">
			<a rel="license"
				href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
				<img alt="Creative Commons License"
					style={{borderWidth:0}}
					src="https://static.alphavega.com/pngs/cc-ancnd-88x31.png"
				/>
			</a>
			<br />{"This work is licensed under a "}
			<a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License</a>.
		</section>
	);
}
import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { loadAlbum, loadPhotos } from "../store/reducers/Album";

import Header from "../album/Header";
import TimeThumb from "./TimeThumb";
import TimelapseFocus from "./TimelapseFocus";
import { TIME_ALBUM } from "utils/common";

import "./Timelapse.css";

export class Timelapse extends React.Component {

	componentWillMount(){
		this.props.loadAlbum();
	}

	// componentDidUpdate(prevProps){
	// 	var currentProps = this.props;

	// 	if(prevProps.match.params.album !== currentProps.match.params.album){
	// 		currentProps.loadAlbum(currentProps.match.params.album);
	// 	}
	// }
	
	render(){
		var { props } = this;

		if(props.match && props.match.params && props.match.params.scene){
			return <TimelapseFocus photoName={props.match.params.scene} />;
		}

		return (
			<article className="the-timelapse">
				<Header type="album" />
				<Helmet>
					<title>Time-Lapse Videos</title>
					<meta name="description" content={`Timelapses are pretty ace.  Here are a bunch of the ones that I've made.`} />
					<meta property="og:title" content="Timelapses I've taken" />
					<meta property="og:type" content="website" />
					<meta property="og:url" conetne={`https://steve.deadlycomputer.com/timelapse/`} />
				</Helmet>
				<article className="timelapse-list">
					{this.props.photoList.map((v) => {
						return <TimeThumb {...v} key={v.id} />;
					})}
				</article>
			</article>
		);
	}

}

function mapStateToProps(state, ownProps){
	return {
		photoList:state.album.photos,
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		loadAlbum(){
			dispatch(loadAlbum(TIME_ALBUM));
			return dispatch(loadPhotos(TIME_ALBUM));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Timelapse);

import React from 'react';
import PropTypes from "prop-types";

import Micro from "../utils/Micro";
import "./Rating.css";

export default class Rating extends React.Component {

	static defaultProps = {
		min:       1,
		max:       10,
		value:     null,
		showMicro: true,
		aggregate: false,
		totalRatings:0,
	};

	static propTypes = {
		max:   PropTypes.number,
		min:   PropTypes.number,
		value: PropTypes.number.isRequired
	};

	render(){
		var me = this
			, props   = me.props
			, ratingType = props.aggregate ? "AggregateRating" : "Rating"
			, $rating = []
			, rate    = props.value
			, i       = props.min
			, micro = [{
				itemProp: "worstRating",
				content:  props.min
			},{
				itemProp: "bestRating",
				content:  props.max
			},{
				itemProp: "ratingValue",
				content:  rate
			}]
			, divProps = {
				itemType: `http://schema.org/${ratingType}`,
				title: `${rate} of ${props.max} stars!`,
				className: "rating-holder",
			}
			;

		while(i < props.max){
			let isRated = (i <= rate) ? "is-filled-in" : "";

			$rating.push(<i key={i} className={`fa fa-star ${isRated} rating-star`} />);
			i++;
		}

		//Only render the Micro data if we need it
		if(!props.showMicro){
			return <div className="rating-holder" title={`${rate} of ${props.max} stars!`}>{$rating}</div>;
		}

		if(props.aggregate){
			micro = [].concat(micro,[{
				itemProp:"ratingCount",
				content:props.totalRatings,
			},{
				itemProp:"reviewCount",
				content:props.totalRatings,
			}]);
			divProps.itemProp = "aggregateRating";
		}

		return (
			<div itemScope {...divProps}>
				{$rating}
				<Micro micro={micro} />
			</div>
		);
	}
}
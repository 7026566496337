
import React from "react";
// import Waypoint from "react-waypoint";
import { connect } from 'react-redux';

import { refreshLeaflet, setMapZoon } from "../store/reducers/Map";
import { loadGEO } from "../store/reducers/Park";
import MapContainer from "../map/MapContainer";

import "./ParkMap.css";

export class ParkMap extends React.Component {

	static defaultProps = {
		isMapLoaded:  false,
		isGEOLoaded:  false,
		showWaypoint: true,
		geoJSON:      null,
	};

	componentDidMount(){
		this.props.setMapZoon(6);
		if(this.props.isGEOLoaded && this.props.geoJSON){
			this.props.setGEO(this.props.geoJSON);
		}
	}

	_loadGEO = () => {
		var me = this
			, props = me.props
			;

		if(props.geoJSON){
			props.setGEO(props.geoJSON);
		} else {
			props.loadGEOJSON();
		}
	}

	render(){
		var me = this
			, props = me.props
			, geoJSON = (props.isGEOLoaded && props.geoJSON) ? props.geoJSON : null
			;

		return (
			<div className="park-map">
				<p>Modern technology is so cool!  I have a handheld GPS unit that comes in really handy for tracking the many miles of activity I did in <span className="map-park-name">{props.name}</span>. (It's also pretty good at making sure I don't get lost and die).  I've used it to highlight the paths hiked through each park, as well as geo-tag the individual photographs taken.</p>
				<MapContainer
					hasLoadedEverything={true}
					mapName = "PARK_MAP"
					// center={props.center}
					hasGeoJSON={geoJSON}
					geoJSON={geoJSON} />
			</div>
		);
	}
}


function mapStateToProps(state, ownProps){
	var currentPark = state.park;

	return {
		center:currentPark.coordinates,
		isMapLoaded:  state.map.isLeafletLoaded,
		isGEOLoaded:  !!(currentPark.geoJSON),
		geoJSON:      currentPark.geoJSON,
		showWaypoint: state.map.showWaypoint,
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		setMapZoon(zoom){
			return dispatch(setMapZoon(zoom));
		},
		loadGEOJSON(){
			return dispatch(loadGEO(ownProps.parkName))
				.then(resp => {
					return dispatch(refreshLeaflet());
				});
		},
		setGEO(geoJSON){
			dispatch(refreshLeaflet());
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ParkMap);
import React from "react";
import { connect } from "react-redux";

import { toggleNav, shouldLoadNav } from "../store/reducers/Nav";
import NavGroup from "./NavGroup";
import {
	Home
	, America
	, About
	, Usage
	, Three60
	, Time
	, NewZel
} from "./StaticItems";
import { trackEvent } from "../utils/Tracking";

import "./Nav.css";

export class Nav extends React.Component {

	static defaultProps = {
		isNavOpen: false,
		toggleNav:() => {},
	};

	_renderGroups = () => {
		if(!this.props.isNavOpen){ return null; }

		return [
			<Home key="home" />
			, <NavGroup key="albums" type="albums" />
			, <NavGroup key="parks" type="parks" />
			, <America key="america" />
			, <NewZel key="newzealand" />
			, <Three60 key="360" />
			, <NavGroup key="states" type="states" />
			, <Time key="time" />
			, <About key="about" />
			, <Usage key="usage" />
		];
	}

	render(){
			var me = this
				, props = me.props
				, openClass = props.isNavOpen ? "is-open" : ""
				;

			return (
				<nav className={`Zion-Navigation ${openClass}`}>
					<button className="button-grid collapse"
						onClick         = {() => {
							props.toggleNav(props.isNavOpen);
							trackEvent("Open Navigation", props.isNavOpen, "via-button");
						}}
						type            = "button"
						aria-label      = "Toggle Navigation">
						<span className = "square-grid" />
					</button>
					<section className={`navigation-holder`}>
						{me._renderGroups()}
					</section>
				</nav>
			);
		}
}

function mapStateToProps(state, ownProps){
	return {
		isNavOpen: state.nav.isNavOpen
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		toggleNav(show){
			dispatch(shouldLoadNav());
			dispatch(toggleNav(!show));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
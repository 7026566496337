import React from "react";
import Gallery from 'react-photo-gallery';
import Lightbox from "react-images";
import Helmet from "react-helmet";

import ImageItem from "./ImageItem";
import { Author, License } from "../utils/common";
import Micro from "../utils/Micro";
import "./PhotoGallery.css";

export default class PhotoGallery extends React.Component {

	static displayName = "PhotoGallery";

	static defaultProps = {
		photoList:[],
		randomPhoto:0,
	};

	state = { isLightboxOpen: false, currentImage:0 };

	openLightbox(photo){
		this.setState({
			isLightboxOpen:true,
			currentImage:photo.index,
		});
	}

	closeLightbox = () => {
		this.setState({ isLightboxOpen:false });
	}

	onPrev = () => {
		var { props, state } = this
			, nextImage = state.currentImage -= 1
			;

		if(nextImage < 0){
			nextImage = props.photoList.length - 1;
		}

		this.setState({
			currentImage:nextImage,
		});
	}

	onNext = () => {
		var { props, state } = this
			, nextImage = state.currentImage += 1
			;

		if(nextImage > props.photoList.length){
			nextImage = 0;
		}

		this.setState({
			currentImage:nextImage,
		});
	}

	_renderOG = () => {
		var { props } = this
			, randImg = props.photoList[props.randomPhoto]
			;

		if(!randImg){ return null; }

		return (
			<Helmet>
				<meta property="og:image" content={randImg.src} />
				<meta property="og:image:width" content="200" />
				<meta property="og:image:height" content="200" />
				<meta property="og:image:type" content="image/jpeg" />
				<meta property="og:image:alt" content={randImg.name} />
			</Helmet>
		);

	}

	render(){
		var { props, state } = this
			, photoMicro = props.photoList.map((v) => {
				let aut = Author;

				if(!/stephen giorgi/gi.test(v.taken_by)){
					aut = {
						itemProp:"author",
						content:v.taken_by,
					};
				}
				return {
					id:v.id,
					micro:[{
						itemType:"https://schema.org/Photograph",
						itemProp:"photo",
						child:[{
							itemProp:"description",
							content:v.desc,
						},{
							itemProp:"name",
							content:v.name,
						},{
							itemProp:"dateCreated",
							content:v.taken,
						}, License, aut],
					}]
				};
			});

		return (
			<section className="photo-gallery">
				{this._renderOG()}
				<Gallery
					photos={props.photoList}
					ImageComponent={ImageItem}
					onClick={(evt, photo) => {
						this.openLightbox(photo)
					}} />
				<Lightbox
					backdropClosesModal
					images={props.photoList}
					onClose={this.closeLightbox}
					onClickPrev={this.onPrev}
					onClickNext={this.onNext}
					currentImage={state.currentImage}
					isOpen={state.isLightboxOpen}
				/>
				{photoMicro.map(v => <Micro key={v.id} micro={v.micro} />)}
			</section>
		);
	}

}

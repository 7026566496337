import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";

import { load360Photos, select360Photo, setPhotoSelection } from "reducers/Three60";
import { trackTime, trackEvent } from "utils/Tracking";
import Three60PhotoList from "./Three60PhotoList";

export class Photo360 extends React.Component {

	static defaultProps = {
		photoList:[],
		currentPhoto:{},
	};

	static propTypes = {
		photoList:PropTypes.array.isRequired,
	};

	componentWillMount(){
		var me = this
			, { props } = this
			, then = new Date().getTime()
			;

		props.load360Photos()
			.then((resp) =>{
				let now = new Date().getTime();
				trackTime(now - then, "Load Photos");
				trackEvent("360","Loaded 360 photos", null, (now - then));
				if(props.match && props.match.params && props.match.params.photo){
					props.setSelectedPhoto(props.match.params.photo)
						.then(me.setURL)
						.catch(() => console.log("uh oh"));
				}
			})
	}

	setURL(resp){
		// console.log("Resp=", resp);
	}
	setURL2 = (resp) => {
		try {
			this.props.history.location.replace(resp.photo.name);
		} catch (err){
			console.error("Problem", err);
		}
	}
	
	render(){
		return (
			<article className="three-360-photo-view">
				<Helmet>
					<title>360° Photos & Video</title>
					<meta name="description" content="Check out some fully immersive 360° photos and videos from my various trips" />
					<meta property="og:type" content="website" />
					<meta property="og:url" content="https://steve.deadlycomputer.com/360/" />
				</Helmet>
				<Three60PhotoList
					photoList={this.props.photoList}
					currentPhoto={this.props.currentPhoto}
					onPhotoSelection={this.props.selectPhoto}
				/>
			</article>
		);
	}

}


function mapStateToProps(state, ownProps){
	return {
		photoList:state.three60.photos,
		currentPhoto:state.three60.currentPhoto,
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		load360Photos(){
			return dispatch(load360Photos());
		},
		selectPhoto(ind, pid){
			return dispatch(select360Photo(pid));
		},
		setSelectedPhoto(photoInfo){
			return dispatch(setPhotoSelection(photoInfo));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Photo360));

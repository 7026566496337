import React from "react";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { prettyVisits } from "../selectors/Park";
import { selectDate } from "../store/reducers/Park";
import ReviewDay from "./ReviewDay";

import "./react-tabs.css";

export class TripVisits extends React.Component {

	static displayName = "TripVisits";

	static defaultProps = {
		visits:[],
		currentVisitDay:0,
	};

	_renderTabs(){
		var { props } = this
			, headers = []
			, pannels = []
			;

		props.visits.forEach((day) => {
			headers.push(<Tab key={day.day_id}>{day.day}</Tab>);
			pannels.push(
				<TabPanel key={day.day_id}>
					<ReviewDay {...day} />
				</TabPanel>
			);
		});

		return {
			headers,
			pannels,
		};
	}

	render(){
		var { props} = this
			, visitInfo = this._renderTabs();

		return (
			<section className="visit-details" itemScope itemProp="reviews">
				<Tabs onSelect={props.selectTab} selectedIndex={props.currentVisitDay}>
					<TabList>
						{visitInfo.headers}
					</TabList>
					{visitInfo.pannels}
				</Tabs>
			</section>
		);
	}

}

function mapStateToProps(state, ownProps){
	return {
		visits:prettyVisits(state),
		currentVisitDay:state.park.currentVisitDay,
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		selectTab(index, lastIndex, evt){
			return dispatch(selectDate(index));
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(TripVisits);

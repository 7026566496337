import React from "react";
import { connect } from "react-redux";

import { getDayActivities } from "../selectors/Park";
import Activity from "./Activity";
import "./Activity.css"

export const ACT_URL = "//static.alphavega.com/images/svgs/icons/";

export class Activities extends React.Component {

	static defaultProps = {
		name:"",
		activities:[],
		daysActivities:[],
	};

	render(){
		// TODO: make this an ItemList
		// itemScope itemType="https://schema.org/ItemList"
		return (
			<aside className="activity-list">
				<h4>Activities</h4>
				<p>These are some of the popular activities you can do at <span className="activity-park-name">{this.props.name}</span>.  These are by no means the only things you can do though.</p>
				<div className="activity-groups">
					{this.props.activities.map((aa) => {
						return <Activity key={aa.name} {...aa} />
					})}
				</div>
			</aside>
		);
	}

}

function mapStateToProps(state, ownProps){
	return {
		activities:state.park.activities,
		daysActivities:getDayActivities(state),
	};
}

export default connect(mapStateToProps, null)(Activities);

import React from "react";
import { Link } from "react-router-dom";

export default class NavItem extends React.Component {

	static defaultProps = {
		type: "album",
		name: "Pond",
		desc: "",
		id:   1,
		albums:[],
	};

	_renderCollection(){
		if(this.props.type === "album") { return null; }

		return (
			<div className="album-items">
				{this.props.albums.map(v => <NavItem {...v} key={v.id} />)}
			</div>
		);
	}

	render(){
		var props = this.props
			, type = props.type === "album" ? "Photos" : "Albums"
			, itemWidth = ""
			, $icon = null
			, $word = null
			, $totalIcon = <i className="fa fa-image item-album" />
			;

		if(props.albums.length > 4){
			itemWidth = "medium-collection";
			if(props.albums.length > 6){
				itemWidth = "wide-collection";
			}
		}

		if(props.type === "album"){
			$icon = <i className="svg-icon photo-album" />;
			$word = "photos";
		} else {
			$totalIcon = <i className="svg-icon photo-album item-collection" />;
			$word = "albums";
		}

		return (
			<article className={`nav-item ${props.type} ${itemWidth}`}>
				<Link to={`/${props.type}/${props.name}/`} title={props.name}>{props.name}</Link>
				<div className="album-item-list">
					{$icon}
					<p className="total-nav">{props[`total_${type.toLowerCase()}`]} {$word}{$totalIcon}</p>
					{this._renderCollection()}
				</div>
			</article>
		);
	}
}
import { createSelector } from "reselect";
import { parse, format } from "date-fns";
import numeral from "numeral";

import { reverseCoordinates } from "../utils/Normalizers";

function getVisits(state){ return state.park.my_details; }
function getPark(state){ return state.park; }
function getAllParks(state){ return state.usa.parks; }

function extractPhotos(visits){
	var photos = []
		, three60 = []
		;

	if(!visits){ return photos; }

	visits.forEach((vv) => {
		vv.photos.forEach((pp) => {
			var p = Object.assign({}, pp);
			p.src= `//steve.deadlycomputer.com/dims/large/${pp.file_name}`;
			p.alt = pp.name;
			p.caption = pp.desc;

			if(p.is360){
				p.src = `//steve.deadlycomputer.com/dims/360/${pp.file_name}`;
				three60.push(p);
			} else {
				photos.push(p);
			}
		});
	});

	return {
		normal:photos,
		three60
	};
}

function makePretty(park){
	var pretty = {}
		, totalDays = 0
		, avg = 0
		;

	if(!park || Object.keys(park).length === 0){ return pretty; }

	pretty.est = format(parse(park.date_established), "MMMM DD, YYYY");
	pretty.area = numeral(park.area).format("0.0a");

	if(park.my_details && park.my_details.length > 0){
		totalDays = park.my_details.length;
		// I could probably combine this into something smaller and nicer, but this works and is easy to understand
		avg = (park.my_details
					.map(v => v.rating)
					.reduce((a,b) => a + b, 0)) / park.my_details.length;
	}

	return {
		states:park.states,
		nps_home:park.nps_home,
		parkName:park.name,
		...pretty,
		raw:{
			area:park.area,
			est:park.date_established,
		},
		visits:{
			totalDays,
			avgRating:avg,
		},
	};
}

function niceVisits(park){
	if(!park || !park.my_details){ return []; }

	return park.my_details.map(v => {
			var oo = Object.assign({}, v);
			oo.day = format(parse(v.date), "MMM DD, YYYY");

			return oo;
		});
}

function extractMarkers(parks){
	if(!parks || parks.length === 0){ return []; }

	return parks.map((v) => {
		var extra = {};

		if(!v.visited){
			extra.opacity = 0.4;
			// extra.icon = {
			// 	iconUrl:"https://static.alphavega.com/images/svgs/US-NationalParkService-Logo.svg",
			// };
		}

		return {
			name:v.name,
			id:v.id,
			position:reverseCoordinates(v.coordinates),
			photo:v.photo,
			review:v.review,
			haveVisited:!!(v.visited),
			className:!!(v.visited) ? "has-visited-park" : "",
			...extra,
		};
	});
}

function getActivities(park){
	var acts = [];
	try {
		acts = park.my_details[park.currentVisitDay].activities;
	} catch (err){}

	return acts;
}

function getAllActivities(park){
	var acts = []
		, tempHolder = {}
		;

	if(!park.my_details){ return []; }

	park.my_details.reduce((acc, current, idx, src) => {
		return acc.concat(current.activities);
	}, []).forEach((v) => {
		if(!tempHolder[v.id]){
			acts.push(v);
			tempHolder[v.id] = v;
		}
	});

	return acts;
}

function makeObj(park){
	var obj = {}
		, acts = [];

	try {
		acts = park.my_details[park.currentVisitDay].activities;
	} catch (err){}


	acts.forEach((v) => {
		obj[v.id] = v;
	});
	return obj;
}

export const photoSelector = createSelector([getVisits], extractPhotos);
export const parkInfo = createSelector([getPark], makePretty);
export const prettyVisits = createSelector([getPark], niceVisits);
export const getParkMarkers = createSelector([getAllParks], extractMarkers);
export const getDayActivities = createSelector([getPark], getActivities)
export const getAllVisitActivities = createSelector([getPark], getAllActivities);
export const getTodayActs = createSelector([getPark], makeObj);
import React from "react";

import "./NextPhoto.css";

export default function NextPhoto(props){
	var asideP = {
			className:"next-photo-control",
			title:props.title,
		}
		, icon = "fa-chevron-right"
		;

	if(props.isDisabled){
		asideP.title = "End of the Line";
		asideP.className += " is-disabled";
	} else {
		asideP.onClick = props.onClick;
	}

	if(props.type === "previous"){
		asideP.className += " is-previous";
		icon = "fa-chevron-left";
	}

	return (
		<aside {...asideP}>
			<i className={`next-photo fa ${icon}`} />
		</aside>
	);
}

NextPhoto.displayName = "NextPhoto";
NextPhoto.defaultProps = {
	title:"Next Photo",
	type:"next",
	isDisabled:false,
};
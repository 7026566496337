import React from "react";
import { Link } from "react-router-dom";
import { parse, format } from "date-fns";

import NPSLogo from "../nps/NPSLogo";
import "./ParkInfo.css";

export default function ParkInfo(props){
	if(!props || !props.name){ return null; }

	return (
		<aside className="photo-park-info">
			<header className="park-name">
				<NPSLogo />
				<h3><Link to={`/park/${props.name}/`}>{props.name}</Link></h3>
			</header>
			<p>This photo is a part of one of my National Park Trips, specifically to {props.name} in {props.state}.  It was part of what I considered a <span className="rating" data-rating={props.rating}>{props.rating}</span> out of <span className="max-rating">10</span> day on <time dateTime={props.date}>{format(parse(props.date), "MMMM DD, YYYY")}</time>.</p>
		</aside>
	);
}


/**
 * Usage File
 * This is the Information for the Usage section of the site
 * It contains the basic Usage information for the Photos
 */
import React		from 'react';
import Helmet		from 'react-helmet';
import { Link } from "react-router-dom";
import "./About.css";

// import { TrackEvent } from "../../constants/Tracking";

const DEPS = {
	"react":               "https://facebook.github.io/react/",
	"redux":               "http://redux.js.org/",
	"redux-thunk":         "https://github.com/gaearon/redux-thunk",
	"react-router":        "https://github.com/ReactTraining/react-router",
	"axios":               "https://github.com/mzabriskie/axios",
	"react-Helmet":        "https://github.com/nfl/react-helmet",
	"react-waypoint":      "https://github.com/brigade/react-waypoint",
	"Leaflet":             "http://leafletjs.com/",
	"react-leaflet":       "https://github.com/PaulLeCam/react-leaflet",
	"react-tabs":"https://github.com/reactjs/react-tabs",
	// "react-scroll":        "https://github.com/fisshy/react-scroll",
	"react-images": "https://github.com/jossmac/react-images",
	"numeral.js":"http://numeraljs.com/",
	"date-fns":"https://date-fns.org/",
	"a-frame":"https://aframe.io/",
	"aframe-react":"https://github.com/supermedium/aframe-react",
	"webpack":             "https://webpack.github.io/",
	"babel":               "https://babeljs.io/",
	"classnames":          "https://github.com/JedWatson/classnames",
	"SVG Loaders":"http://samherbert.net/svg-loaders/",
}

export default class About extends React.Component {

	render(){
		var $tech = []
			, t = null
			;

		for(t in DEPS){
			$tech.push(
				<li key     = {t}>
					<a href    = {DEPS[t]}
						title   = {t}
						target  = "_TECH">{t}</a>
				</li>
			);
		}

		return(
			<div className="about-holder">
				<Helmet>
					<title>About Me & This Site</title>
					<meta name="description" content="Learn about all the stuff I use for this website!" />
					<meta property="og:title" content="About Me & This Site" />
					<meta property="og:type" content="website" />
					<meta property="og:url" content="https://steve.deadlycomputer.com/about/" />
				</Helmet>
				<h1>About Stuff</h1>
				<article className="photo-usage">
					<h2>About Me</h2>
					<p>If it's not already apparent from this website, I love taking photographs.  I've been doing it for years now, as long as I can remember I've enjoyed documenting the world around me for pleasure.  This website, that I've built myself (more on that below), is where I showcase to the world my work.</p>
					<p>If you'd like to see more, cruise around, there's plenty here.  If you'd like to use them for yourself somewhere, <Link to="/usage/">Check out the Usage Rights</Link>, they're pretty straightforward to understand.</p>
				</article>
				<article>
					<h2>About This Site</h2>
					<p>As I alluded to above, working on websites is one of my other passions.  In fact, it's my full time job.  That is why if you've seen my photos over the years, you might not recognize the site, I've gone through about 10 different re-writes and re-designs by my own count.  I use this as my playground to keep myself up to date on the ever changing world of Front End Web Development.</p>
					<h3>Technology Used</h3>
					<p>I'd like to call out the following libraries, without them, this website would have been immesensly more difficult to build:</p>
					<ul>{$tech}</ul>
					<h3>Assets</h3>
					<ul>
						<li>photo album by H Alberto Gongora from the Noun Project</li>
						<li>Film by Pavel Diatchenko from the Noun Project</li>
						<li>Forest Landscape by Tatina Vazest from the Noun Project</li>
						<li>New Zealand map by Bence Bezeredy from the Noun Project</li>
						<li>Sunny Rainbow by Baboon designs from the Noun Project</li>
						<li>Old Faithful by Nick Bluth from the Noun Project</li>
						<li>native tribe dancing by Gan Khoon Lay from the Noun Project</li>
						<li>cave by Gerardo Martín Martínez from the Noun Project</li>
						<li>beach by Andi Nur Abdillah from the Noun Project</li>
						<li>river by Ninejipjip from the Noun Project</li>
						<li>Mountain by Iga from the Noun Project</li>
						<li>Camera Lens by Dao Ge from the Noun Project</li>
					</ul>
				</article>
			</div>
		);
	}

};

import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import { loadPark, loadGEO, select360Photo, selectDate } from "../store/reducers/Park";
import { setMapCenter } from "../store/reducers/Map";
import { photoSelector } from "../selectors/Park";

import TripVisits from "../nps/TripVisits";
import ParkMap from "../nps/ParkMap";
import PhotoGallery  from "../album/PhotoGallery";
import Three60PhotoList from "../pano/Three60PhotoList";

import AllActivities from "./AllActivities";
import "../nps/Park.css";
import "../nps/Responsive.Park.css";
import "./NewZealand.css";

export class NewZealand extends React.Component {

	static displayName = "NewZealand";

	static defaultProps = {
		photoList:[],
		three60:[],
		name:"New Zealand",
		selected360:-1,
		totalDays:0,
	};

	componentWillMount(){
		var { props } = this;

		props.loadPark("New Zealand")
			.then((resp) => {
				if(props.history && props.history.location && props.history.location.hash){
					let visitDay = null;
					try {
						/* eslint-disable */
						visitDay = props.history.location.hash.replace(/\#/gi, "");
						/* eslint-enable */
					} catch (err){}

					props.setVisit(parseInt(visitDay));
				}	//end if(props.history)
			});
		props.loadGEO("New Zealand");
	}

	render(){
		var { props } = this;

		return (
			<section className="park-details new-zealand"
				itemScope
				itemType="https://schema.org/Place">
				<header className="park-header">
					<Helmet>
						<title>Our New Zealand Honeymoon</title>
						<meta property="og:title" content="New Zealand Honeymoon" />
						<meta property="og:type" content="website" />
						<meta property="og:url" content="https://steve.deadlycomputer.com/trip/New%30Zealand/" />
						<meta property="og:description" content="Maryellen and I choose New Zealand as our destination for our Honeymoon.  Highlights of this trip include: penguins, amazing stars, wonderful food, and memories to last a lifetime!" />
						<meta property="twitter:label1" value="Total Days" />
						<meta property="twitter:data1" value={props.totalDays} />
						<meta property="twitter:label2" value="Total Photos" />
						<meta property="twitter:data2" value={props.photoList.length} />
					</Helmet>
					<div className="left">
						<h1 className="park-name" itemProp="name">New Zealand</h1>
					</div>
					<aside className="other-links">
						<p>You can check out more of the photos at it's <Link to="/collection/New%20Zealand/">dedicated collection</Link>.  There are 6 albums containinng almost 100 photos from the trip.  Yes we took over 1,700 photos, but those 100 are the prime ones.</p>
					</aside>
				</header>
				<div className="trip-info">
					<TripVisits />
					<AllActivities />
				</div>
				<PhotoGallery photoList={props.photoList} />
				<Three60PhotoList
					photoList={props.three60}
					onPhotoSelection={props.onSelected360Photo}
					selected360={props.selected360}
				/>
				<ParkMap name={props.name} />
			</section>
		);
	}

}

function mapStateToProps(state){
	var photoList = photoSelector(state)
		, totalDays = 0
		;

	if(state.park && state.park.my_details){
		totalDays = state.park.my_details.length;
	}

	return {
		photoList:photoList.normal,
		three60:photoList.three60,
		name:state.park.name,
		selected360:state.park.selected360,
		totalDays,
	};
}

function mapDispatchToProps(dispatch){
	return {
		loadPark(parkName){
			return dispatch(loadPark(parkName));
		},
		loadGEO(parkName){
			// dispatch(loadGEO(parkName, "Waypoints"));
			return dispatch(loadGEO(parkName));
		},
		onSelected360Photo(index){
			return dispatch(select360Photo(index));
		},
		setVisit(visitDay){
			return dispatch(selectDate(visitDay));
		},
		setMapCenter(center){
			return dispatch(setMapCenter(center));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NewZealand);
/** Common things */

import { parse, format } from "date-fns";

export const NPS_URL = "https://www.nps.gov/";
export const DIMS_URL = "https://steve.deadlycomputer.com/dims/";
export const TIME_ALBUM = 142;

export const STEPHEN       = {
	itemProp:"name",
	content:"Stephen Giorgi"
};

export const Author = {
		itemProp:"author",
		itemType:"http://schema.org/Person",
		child:[STEPHEN]
	};

export const Copyright = {
		itemProp:"copyrightHolder",
		itemType:"http://schema.org/Person",
		child:[STEPHEN]
	};

export const License = {
		itemProp:"license",
		content:"https://steve.deadlycomputer.com/usage/"
	};

export function mathInfo(value){
	var parts = [], nums = [];
	try {
		parts = value.split("/");
	} catch (err){
		return value;
	}
	if(parts.length !== 2){ return value; }

	nums = parts.map(v => parseInt(v));

	return nums[0] / nums[1];
}

export const EXIF_ICONS = [{
		value: "FNumber",
		class: "aperature-icon custom-icon",
		title: "Aperature",
		formatter(value){
			return `f/${mathInfo(value)}`;
		}
	},{
		value: "FocalLength",
		class: "fa fa-sun-o",
		title: "Focal Length",
		formatter(value){
			return `${mathInfo(value)} mm`;
		},
	},{
		value: "hasGPS",
		class: "fa fa-globe",
		title: (p) => {
			if(p.hasGPS === "Nope") { return "No Location!";}
			return "Located Here";
		}
	}, {
		value: "ExposureTime",
		class: "custom-icon camera-timer-icon",
		title: "Shutter Speed",
		// formatter:mathInfo,
	}, {
		value: "hasFlash",
		class: "fa fa-lightbulb-o flash",
		title: (p) => {
			if(p.hasFlash === "Off"){ return "No Flash"; }
			return "Used Flash";
		}
	}, {
		value: "ISOSpeedRatings",
		class: "custom-icon film-container-icon",
		title: "Fiml Speed",
		formatter(value){
			return `${value} ISO`;
		}
	},{
		value: "Model",
		class: "fa fa-camera-retro",
		title: "Camera"
	},{
		value:"UndefinedTag:0xA434",
		class:"custom-icon camera-lens-icon",
		title:"Lens",
	},{
		value: "takenOn",
		class: "fa fa-calendar",
		title: "Taken on",
		formatter:function(value){
			return format(parse(value), "MMMM DD, YYYY");
		}
	},{
		value:"iptc",
		class:"fa fa-tags",
		title:"Tags",
		formatter(value){
			if(!value.Tags){ return null; }

			return value.Tags.join(", ");
		}
	}];

export function generateActivitySchema(activity){
	var itemType = "";

	switch(activity.toLowerCase()){
		case "Airplane":
		case "scenic drive":
		case "wildlife":
		case "waterfall":
		case "waterfalls":
			itemType = "http://schema.org/Thing";
			break;
		default:
			itemType = "http://schema.org/PhysicalActivity";
			break;
	}

	return itemType;
}

export const JSDON = {
	"@context":    "http://schema.org",
	"@type":       "Person",
	"name":        "Stephen Giorgi",
	"url":         "https://steve.deadlycomputer.com",
	"nationality": "United States of America",
	"email":       "steve@deadlycomputer.com",
	"gender":      "male",
	"sameAs":      [
		"https://www.instagram.com/lord.xeon",
		"https://www.viewbug.com/member/lord-xeon",
		"https://www.youtube.com/user/deadlycomputervideo",
		"https://gitlab.com/lord.xeon",
		"https://blog.deadlycomputer.com/"
	]
};
import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { parkInfo } from "../selectors/Park";

import NPSLogo from "./NPSLogo";
import Rating from "./Rating";
import State from "./State";
import { NPS_URL } from "../utils/common";
import "./ParkHeader.css";

export class ParkHeader extends React.Component {

	static displayName = "ParkHeader";

	static defaultProps = {
		name:"",
		est:"",
		area:0,
		states:[],
		raw:{},
		visits:{
			totalDays:0,
			avgRating:0,
		},
	};

	_renderVisits(){
		var { visits } = this.props;

		if(visits.totalDays === 0){
			return <span>I have not visited this park yet</span>;
		}

		return <span>{visits.totalDays} days.  Average of: <Rating type="average" value={visits.avgRating} totalRatings={visits.totalDays} aggregate /></span>;
	}

	render(){
		var { props } = this;

		return (
			<header className="park-header">
				<Helmet>
					<title>{`My Visits to - ${props.parkName}:`}</title>
					<meta name="description" content={`Author: Stephen Giorgi; This is a break down of my visit to ${props.parkName} National Park;  Total Time: ${props.visits.totalDays}; My Rating: ${props.visits.avgRating}; Location: ${props.states.map(v =>v.name)}; Sub-Location: ${props.parkName}`} />
					<meta property="og:title" content={`${props.parkName} - trip`} />
					<meta property="og:type" content="website" />
					<meta property="og:url" content={`https://steve.deadlycomputer.com/park/${props.parkName}/`} />
				</Helmet>
				<div className="left">
					<div className="name-side">
						<h1 className="park-name" itemProp="name">{props.parkName}</h1>
						<a href={`${NPS_URL}${props.nps_home}`} title="Visit NPS Homepage" itemProp="url" className="nps-link" target="_NPS">
							<NPSLogo />
						</a>
					</div>
					<div className="visit-info" itemScope>{this._renderVisits()}</div>
				</div>
				<aside className="info-side">
					<time className="est" dateTime={props.raw.est}>{props.est}</time>
					<div className="area">Covers: {props.area} acres</div>
					<div className="states">
						Within the state of:<State states={props.states} />
					</div>
				</aside>
			</header>
		);
	}

}

function mapStateToProps(state, ownProps){

	return {
		...parkInfo(state),
	};
}

export default connect(mapStateToProps, null)(ParkHeader);
import { ReduceAll } from "./ReduceAll";

export const THREE_60_ACTIONS = {
	LOAD_THREE_60_AJAX:"LOAD_THREE_60_AJAX",
	LOAD_THREE_60_SUCCESS:"LOAD_THREE_60_SUCCESS",
	LOAD_THREE_60_FAIL:"LOAD_THREE_60_FAIL",

	SET_THREE_60_PHOTO:"SET_THREE_60_PHOTO",
	SET_PHOTO_FROM_URL:"SET_PHOTO_FROM_URL",
};

export function load360Photos(){
	return (dispatch, getState) => {
		var currentState = getState().three60;

		if(currentState && currentState.photos && currentState.photos.length){
			return Promise.resolve({ success:true });
		}

		return dispatch({
			type:THREE_60_ACTIONS.LOAD_THREE_60_AJAX,
			meta:{
				AJAX:{
					success:THREE_60_ACTIONS.LOAD_THREE_60_SUCCESS,
					url:"info.php",
					params:{
						type:"photos",
						what:360,
					},
				},
			},
		});
	};
}

export function select360Photo(nextPhotoId){
	return (dispatch) => {
		return dispatch({
			type:THREE_60_ACTIONS.SET_THREE_60_PHOTO,
			payload:nextPhotoId,
		});
	};
}

export function setPhotoSelection(photoInfo){
	return (dispatch, getState) => {
		if(!photoInfo){ return Promise.reject({ success: false }); }

		let currentState = getState().three60
			, photos = currentState.photos
			, len = photos.length
			, pp = 0
			, newSelection = null
			;

		for(pp; pp < len; pp++){
			let currentPhoto = photos[pp];

			if(photoInfo === currentPhoto.id){
				newSelection = currentPhoto;
				break;
			}
			let rgx = new RegExp(photoInfo, "gi");

			if(rgx.test(currentPhoto.name) || rgx.test(currentPhoto.desc) || rgx.test(currentPhoto.file_name)){
				newSelection = currentPhoto;
				break;
			}
		}

		if(newSelection){
			dispatch({
				type:THREE_60_ACTIONS.SET_PHOTO_FROM_URL,
				payload:newSelection,
			});
			return Promise.resolve({ success: true, photo: newSelection });
		}

		return Promise.reject({ success: false });
	};
}


const IT = {
	photos:[],
	currentPhoto:{},
	isPhotosLoaded:false,
};

const handlers = {
	[THREE_60_ACTIONS.LOAD_THREE_60_AJAX]:setPhotosLoading,
	[THREE_60_ACTIONS.LOAD_THREE_60_SUCCESS]:initPhotos,

	[THREE_60_ACTIONS.SET_THREE_60_PHOTO]:setSelectedPhoto,
	[THREE_60_ACTIONS.SET_PHOTO_FROM_URL]:setGivenPhoto,
};

function setPhotosLoading(state, action){
	return {
		...state,
		isPhotosLoaded:false,
	};
}

function initPhotos(state, action){
	return {
		...state,
		photos:action.payload.photos,
		currentPhoto:action.payload.photos[0],
		isPhotosLoaded:true,
	};
}

function setSelectedPhoto(state, action){
	return {
		...state,
		currentPhoto:state.photos.filter(v => v.id === action.payload)[0],
	};
}

function setGivenPhoto(state, action){
	return {
		...state,
		currentPhoto:action.payload,
	};
}

export default ReduceAll(IT, handlers);


import React from 'react';
import { connect } from 'react-redux';

import LAYERS from './MapLayers';
import { MAP_ACTIONS } from "../store/reducers/Map";

export const USA_CENTER = [39.833333, -98.583333];

export class MapContainer extends React.Component {

	static defaultProps = {
		isLeafletLoaded: false,
		layer:           'Stamen Terrain',
		center:          USA_CENTER,
		zoom:            4,
		children:        [],
		markerComponent: null,
		markers:         [],
		hasGeoJSON:null,
		hasMarkers:null,
	};

	componentDidMount(){
		if(!this.props.isLeafletLoaded){
			this.props.loadLeaflet();
			this.forceUpdate();
		}
	}

	_renderMarkers = () => {
		var me = this
			, props = me.props
			, MarkerComponent = props.markerComponent || MarkerName
			;

		if(!props.hasMarkers){ return null; }

		return props.hasMarkers.map((v, i) => {
				return (
					<LeafletHolder.Marker
						{...v}
						key     = {v.name}
						onClick = {() => me.props.onMarkerClick(v)}>
						<LeafletHolder.Popup>
							<MarkerComponent {...v} />
						</LeafletHolder.Popup>
					</LeafletHolder.Marker>
				);
			});
	}

	_renderGEOJSON = () =>{
		var props = this.props;

		if(!LeafletHolder.GeoJSON || !props.hasGeoJSON) { return null; }

		return <LeafletHolder.GeoJSON data={props.hasGeoJSON} />
	}

	_renderMap = () => {
		var props = this.props
			, currentCenter = USA_CENTER
			;

		if(!props.isLeafletLoaded) { return null; }

		if(typeof(props.center) === "string"){
			currentCenter = USA_CENTER;
		} else {
			currentCenter = props.center;
		}

		return (
			<LeafletHolder.Map
				center={currentCenter}
				zoom={props.zoom}>
				<LeafletHolder.TileLayer {...LAYERS[props.layer]} />
				{this._renderMarkers()}
				{this._renderGEOJSON()}
				{props.children}
			</LeafletHolder.Map>
		);
	}

	render(){
		return <figure className="react-leaflet-map" itemScope itemType="http://schema.org/Map">{this._renderMap()}</figure>;
	}
}

function MarkerName(props){
	return (
		<h2>{props.name}</h2>
	);
}


let LeafletHolder = {
		Marker:   null
		, Popup:  null
		, TileLa: null
		, Icon:   null
	}
	;

function loadLeaflet(){
	var LE = require("react-leaflet")

	LeafletHolder.LE        = LE;
	LeafletHolder.Map       = LE.Map;
	LeafletHolder.Marker    = LE.Marker;
	LeafletHolder.TileLayer = LE.TileLayer;
	LeafletHolder.Popup     = LE.Popup;
	LeafletHolder.GeoJSON   = LE.GeoJSON;

	return {
		type:            MAP_ACTIONS.LEAFLET_LOADED,
		isLeafletLoaded: true
	};
}

function mapStateToProps(state){
	return {
		...state.map,
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		loadLeaflet(){
			dispatch(loadLeaflet());
		},
		onMarkerClick(marker){
			dispatch({
				type: MAP_ACTIONS.CLICK_MARKER,
				payload: {
					...marker,
					mapName: ownProps.mapName
				}
			});
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
import React from "react";
import { DIMS_URL } from "../utils/common";
import { Link } from "react-router-dom";

import Micro from "../utils/Micro";
import { Author, License } from "../utils/common";
import "./PhotoStack.css"

export default class PhotoStack extends React.Component {

	static displayName = "PhotoStack";

	static defaultProps = {
		photos:     [],
		wrapInLink:false,
		link:"",
	}

	render(){
		var props = this.props
			, $ul = (
				<ul className="photo-stack">
					{props.photos.map((val, ind) => {
						let thumbURL = `${DIMS_URL}thumb/${val.file_name}`
							, micro = [
								Author
								, License
								, { itemProp:"name", content:val.name }
								// , { itemProp:"thumbnailUrl", content: thumbURL }
							]
							;
							
						return (
							<li key={`${val.file_name}-${ind}`} itemScope itemType="https://schema.org/Photograph">
									<img src={thumbURL} alt={val.name} itemProp="thumbnailUrl" />
									<Micro micro={micro} />
							</li>
						);
					})}
				</ul>
			)
			;

		if(props.wrapInLink){
			return <Link to={props.link}>{$ul}</Link>
		}

		return $ul;
	}
}

import React from "react";
// import { parse, format } from "date-fns";

import { EXIF_ICONS } from "utils/common";

export default function EXIF(props){
	var $exif   = [], takenOn = /taken/gi;

	$exif = EXIF_ICONS.map(v => {
		let $content = props[v.value]
			, val = props[v.value]
			, title   = ""
			;

		if(!val){ return null; }

		if(typeof(v.title) === "function"){
			title = v.title(props);
		} else {
			title = v.title;
		}

		if(v.formatter){
			$content = v.formatter(val);
		}

		if(takenOn.test(val)){
			$content = <time dateTime={val}>{$content}</time>;
		}

		if(!$content){ return null; }

		return (
			<tr
				key          = {v.value}
				className    = "exif-item"
				title        = {title}>
				<td><i className = {v.class} aria-hidden={true} /></td>
				<td>{$content}</td>
			</tr>
		);
	});	//end EXIF_ICONS.map()

	return (
		<aside className="exif-info">
			<table>
				<tbody>
					{$exif}
				</tbody>
			</table>
		</aside>
	);
}
import {
	applyMiddleware
	, compose
	, createStore
} from "redux";
import thunkMiddleware from "redux-thunk";
// import { routerMiddleware } from "react-router-redux";
// import { browserHistory } from "react-router";

import AJAXMiddleware from "./middleware/AJAX";

import makeRootReducer from "./RootReducer";

function makeStore(initialState = {}, history = null){
	let routerHistory = history;

	if(!history){
		routerHistory = {};
	}

	let middleware = [
		thunkMiddleware
		, AJAXMiddleware
	];

	let enhancers = [];
	let composeEnhancers = compose;

	if(typeof(window) !== "undefined"){
		let composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
		if(typeof(composeWithDevToolsExtension) === "function"){
			composeEnhancers = composeWithDevToolsExtension;
		}
	}

	let store = createStore(
			makeRootReducer(null, routerHistory)
			, initialState
			, composeEnhancers(
				applyMiddleware(...middleware),
				...enhancers
			)
		);

	return store;
}

export default makeStore;

import { ReduceAll } from "./ReduceAll";
import { reverseCoordinates } from "../../utils/Normalizers";

export const USA_ACTIONS = {
	LOAD_ALL_STATS_AJAX:"LOAD_ALL_STATS_AJAX",
	LOAD_ALL_STATS_SUCCESS: "LOAD_ALL_STATS_SUCCESS",

	LOAD_ALL_PARKS_AJAX:"LOAD_ALL_PARKS_AJAX",
	LOAD_ALL_PARKS_SUCCESS: "LOAD_ALL_PARKS_SUCCESS",

	LOAD_PARK_GEO_AJAX:"LOAD_PARK_GEO_AJAX",
	LOAD_PARK_GEO_SUCCESS:"LOAD_PARK_GEO_SUCCESS",
};

export function loadUSAStats(){
	return (dispatch, getState) => {
		var currentState = getState().usa;

		if(currentState.parks && currentState.parks.length){
			return;
		}
		
		return dispatch({
			type: USA_ACTIONS.LOAD_ALL_STATS_AJAX,
			meta:{
				AJAX: {
					url:     "info.php",
					params:{
						type:"usa",
						what:"stats",
					},
					success: USA_ACTIONS.LOAD_ALL_STATS_SUCCESS,
				},
			},
		});
	};
}

export function loadUSAParks(){
	return (dispatch, getState) => {
		var currentState = getState().usa;

		if(currentState && currentState.stats && currentState.stats["Total Parks"] > 0){ return; }
		
		return dispatch({
			type:USA_ACTIONS.LOAD_ALL_PARKS_AJAX,
			meta:{
				AJAX:{
					url:     "info.php",
					params:{
						type:"usa",
						what:"states",
					},
					success: USA_ACTIONS.LOAD_ALL_PARKS_SUCCESS,
				},
			},
		});
	};
}

export function loadParkWaypoints(){
	return (dispatch) => {
		return dispatch({
			type:USA_ACTIONS.LOAD_PARK_GEO_AJAX,
			meta:{
				AJAX:{
					url:"geojson/USA_States.geojson",
					success:USA_ACTIONS.LOAD_PARK_GEO_SUCCESS,
				},
			},
		});
	};
}

export function initPark(park){
	var initialPark = {
			review:    "I haven't made it to this park yet!",
			draggable: false,
			title:     park.name,
			key:       park.name,
			opacity:   park.visited ? 1 : 0.35,
			photo:     {
				file_name: "",
				name:      ""
			},
			...park,
			position: reverseCoordinates(park.coordinates),
		};

	return initialPark;
}

export const IT = {
	parks: null,
	stats: null,
	geoJSON:null,
};

const handlers = {
	[USA_ACTIONS.LOAD_ALL_STATS_SUCCESS]: loadStats,
	[USA_ACTIONS.LOAD_ALL_PARKS_SUCCESS]: loadParks,
	[USA_ACTIONS.LOAD_PARK_GEO_SUCCESS]:initGeo,
};

function loadStats(state, action){
	return {
		...state,
		stats:action.payload
	};
}

function loadParks(state, action){
	return {
		...state,
		parks: action.payload.map(initPark)
	};
}

function initGeo(state, action){
	return {
		...state,
		geoJSON:action.payload,
	};
}

export default ReduceAll(IT, handlers);

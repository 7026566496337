import axios from "axios";

import { trackEvent, trackTime } from "utils/Tracking";

const BASE_AJAX = {
	method:"get",
	baseURL:"https://steve.deadlycomputer.com/world/",
};

function AJAXMiddleware(store){
	return function(next){
		return function(action){
			if(!(action.meta && action.meta.AJAX)){ return next(action); }

			var axiosReq = Object.assign({}, BASE_AJAX, action.meta.AJAX)
				, metaAjax = action.meta.AJAX
				;

			function makeAction(ready, success, data){
				let newAction = Object.assign({}, action, { success });

				if(ready){
					if(success){
						newAction.payload = data.data;
						newAction.original = action.payload;
						if(metaAjax.success){
							newAction.type = metaAjax.success;
						}
					}
				}

				delete newAction.meta.AJAX;
				return newAction;
			}

			var then = new Date().getTime();
			return axios.request(axiosReq)
				.then(
					(resp) => {
						var now = new Date().getTime();
						trackTime(now - then, "AJAX API");
						trackEvent("AJAX", axiosReq.url, null, now - then);
						return next(makeAction(true, (resp.status === 200), resp));
					}
			);	//end axios.request()
		}	//end function(action)
	}	//end function(next)
}	//end AJAXMiddleware()

export default AJAXMiddleware;
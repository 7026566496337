
import React from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import numeral from "numeral";
import { Link } from "react-router-dom";

import { JSDON } from "../utils/common";
import CCLicense from "./CCLicense";
import "./Footer.css"

function JDL(){
	return {
		__html:JSON.stringify(JSDON),
	};
}

export class Footer extends React.Component {

	static displayName = "Footer";

	static defaultProps = {
		total_photos:      1405,
		total_albums:      125,
		total_collections: 19,
		total_parks:23
	};

	render(){
		var props = this.props;

		return (
			<footer id="rainier-footer">
				<aside className="about-section">
					<h4>About</h4>
					<ul>
						<li>{`© 2007–${format(new Date(), "YYYY")} stephen giorgi`}</li>
						<li><a href="/usage/" title="Usage Rights">How to use these photos</a></li>
						<li><a href="/about/" title="About This">About me, and this site</a></li>
						<li>
							<a href="https://www.instagram.com/lord.xeon/?ref=badge" target="_INSTA">
								<i className="fa fa-instagram" />
								Instagram me!
							</a>
						</li>
						<li><a href="https://www.viewbug.com/member/lord-xeon" target="_VIEW">My ViewBug Page</a></li>
						<li><a href="https://gitlab.com/lord.xeon" target="_GIT"><i className="fa fa-gitlab" />Me on GitLab</a></li>
					</ul>
				</aside>
				<aside className="stats-section">
					<h4>Stats</h4>
					<ul>
						<li>
							<label>Total Photos</label>
							<b>{numeral(props.total_photos).format()}</b>
						</li>
						<li>
							<label>Total Albums</label>
							<b>{numeral(props.total_albums).format()}</b>
						</li>
						<li>
							<label>Total Collections</label>
							<b>{numeral(props.total_collections).format()}</b>
						</li>
						<li>
							<label>Total Parks</label>
							<b>{numeral(props.total_parks).format()}</b>
						</li>
						<li>
							<label>One Unforgettable Honeymoon</label>
							<Link to="/trip/New%20Zealand/">to New Zealand</Link>
						</li>
					</ul>
				</aside>
				<aside className="usage-section">
					<h4>License</h4>
					<CCLicense />
				</aside>
				<aside className="sense-section">

				</aside>
				<aside className="tech-section">
					<h4>Tech</h4>
					<ul className="html5-info title list-unstyled">
						<li className="semantics">Uses Semantic Markup</li>
						<li className="css3">Lots of CSS3</li>
						<li className="ddd">Modern Effects</li>
						<li className="storage">Offline Capabilities</li>
					</ul>
				</aside>

				<script type="application/ld+json" dangerouslySetInnerHTML={JDL()} />
			</footer>
		);
	}
}


function mapStateToProps(state, ownProps){
	return {
		...state.footer,
	};
}
export default connect(mapStateToProps, null)(Footer);
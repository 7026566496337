import React from "react";
// import Waypoint from "react-waypoint";

import ThreeThumb from "./ThreeThumb";
import { trackEvent, trackTime} from "utils/Tracking";
import "./Three60PhotoList.css";

var Three60Comp = null;

export default class Three60PhotoList extends React.Component {

	static displayName = "Three60PhotoList";

	static defaultProps = {
		photoList:[],
		selected360:0,
		currentPhoto:null,
		onPhotoSelection:() => {},
	}

	state = { isCompLoaded: false };

	componentWillMount(){
		var then = new Date().getTime();
		import(/* webpackChunkName: "Three60" */ "./Three60")
			.then((comp) => {
				var now = new Date().getTime();
				trackTime(now - then, "Load 360 js");
				trackEvent("360", "Load 360 JS", null, (now - then));
				Three60Comp = comp["default"];
				this.setState({ isCompLoaded: true });
			})
			.catch(error => 'An error occurred while loading the Three60 Component');
	}

	render(){
		var { props } = this
			, $way = null
			;

		if(props.photoList.length === 0){ return null; }

		if(Three60Comp === null){
			// $way = <Waypoint onEnter={this._loadthree} />;
		} else {
			if(props.photoList.length > 0){
				let three60Props = props.photoList[props.selected360];
				if(props.currentPhoto){
					three60Props = props.currentPhoto;
				}
				$way = <Three60Comp {...three60Props} />;
			}
		}

		return (
			<section className="three-60-photo-gallery">
				<header itemScope itemType="https://schema.org/CreativeWork">
					<h2 itemProp="name">360 Photos</h2>
					<p itemProp="description">One of the coolest new technologies I think is 360° photo and video.  I have a camera the takes photos in a full sphere and below you can really get an idea of how things look</p>
				</header>
				{$way}
				<footer className="three-60-photo-list">
					<p>Just like my regular photos, I take more 360° photos than I know what to do with.  Below you can see the ones I felt offered the best views of my various trips.</p>
					<div className="photo-list" itemScope itemType="https://schema.org/ItemList">
						{props.photoList.map((p, ind) => {
							return <ThreeThumb {...p} key={p.id} index={ind} onPhotoSelection={props.onPhotoSelection} />;
						})}
					</div>
				</footer>
			</section>
		);
	}

}

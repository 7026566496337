import React from "react";
import ImgCaption from "../utils/ImgCaption";
import { DIMS_URL } from "utils/common";

import "./ThreeThumb.css";

export default function ThreeThumb(props){
	return (
		<figure className="three-60-photo-thumb" itemScope itemType="https://schema.org/Photograph">
			<img
				itemProp="thumbNailUrl"
				className="three-30-thumb"
				src={`${DIMS_URL}360/t/${props.file_name}`}
				alt={props.name}
				onClick={() => props.onPhotoSelection(props.index, props.id)}
			/>
			<ImgCaption {...props} />
		</figure>
	);
}


ThreeThumb.displayName = "ThreeThumb";
ThreeThumb.defaultProps = {
	file_name:"",
	index:0,
	id:-1,
};
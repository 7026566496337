import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import history from "./nav/history";

import './index.css';
import App from './App';
import makeStore from "./store/store";
import { version } from "../package.json";

var IT = {
	park:{},
};

if(window.halfDome){
	IT = window.halfDome;
}
var routerHistory = null;

function render(Component){
	var store = makeStore(IT, routerHistory)
		, PP = new Promise((resolve, reject) => {
			ReactDOM.hydrate(
				<BrowserRouter history={history}>
					<Component store={store} history={routerHistory} />
				</BrowserRouter>
				, document.getElementById("rainier")
				, () => resolve(store)
			);
		});

	return PP;
}


render(App).then((resp) => {
	try {
		window.__info = {
			version,
		};
	} catch (err){

	}
}).catch(err => console.log(err));
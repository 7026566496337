import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { DIMS_URL } from "../utils/common";

// import Micro from "../utils/Micro";
import "./Header.css";

export class Header extends React.Component {

	static displayName = "Header";

	static defaultProps = {
		name:"",
		desc:"",
		totalPhotos:0,
		type:"album",
		focusPhoto:null,
	};

	render(){
		var { props } = this
			, $albums = null
			, $name = props.name
			, metaType = "photos"
			, totalCount = props.totalPhotos
			, extraMeta = [<meta key="label1" property="twitter:label1" value="Total Photos" />
							, <meta key="data1" property="twitter:data1" value={props.totalPhotos} />]
			;

		if(props.type === "collection"){
			$albums = <span>{props.totalAlbums} total Albums</span>;
			metaType = "albums"
			totalCount = props.totalPhotos;
			extraMeta.push(<meta key="label2" property="twitter:label2" value="Total Albums" />);
			extraMeta.push(<meta key="data2" property="twitter:data2" value={props.totalAlbums} />);
		}

		if(props.focusPhoto){
			extraMeta.push(<meta key="image" property="og:image" content={`${DIMS_URL}thumb/${props.focusPhoto.file_name}`} />);
			extraMeta.push(<meta key="image:alt" property="twitter:image:alt" value={props.focusPhoto.name} />);
		}

		return (
			<header className={`album-header ${props.type}`} itemScope itemType="https://schema.org/CreativeWork">
				<Helmet>
					<title>{$name} - Photo {props.type}</title>
					<meta name="description" content={`Author: Stephen Giorgi; This is a group of ${metaType} which make up the ${$name};  Total ${metaType}: ${totalCount}; Description: ${props.desc}`} />
					<meta property="og:description" content={props.desc} />
					<meta property="og:title" content={`${$name} - ${metaType}`} />
					<meta property="og:type" content="website" />
					<meta property="og:url" content={`https://steve.deadlycomputer.com/${props.type}/${$name}/`} />
					{extraMeta}
				</Helmet>
				<h1 className="album-name" itemProp="name">{$name}</h1>
				<aside className="album-info">
					<p itemProp="description">{props.desc}</p>
					{$albums}
					<span>{totalCount} total Photos</span>
				</aside>
			</header>
		);
	}
}

function mapStateToProps(state, ownProps){
	var infoProps = state[ownProps.type].info;

	return {
		name:infoProps.long_name,
		desc:infoProps.desc,
		totalAlbums:infoProps.total_albums,
		totalPhotos:infoProps.total_photos,
	};
}


export default connect(mapStateToProps, null)(Header);
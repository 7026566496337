import React from "react";
import { Link } from "react-router-dom";

export function Home(props) {
	return (
		<div className="static-nav-item home-item">
			<aside className="icon-side">
				<i className="fa fa-home"  label="Home" />
			</aside>
			<div className="info-side">
				<h3>Home</h3>
				<Link to="/">Let's Go Home</Link>
			</div>
		</div>
	);
}

export function America(props){
	return (
		<div className="static-nav-item america-item">
			<aside className="icon-side">
				<img src="https://static.alphavega.com/images/svgs/Flag_of_the_United_States.svg" title="US Flag" alt="US Flag" />
			</aside>
			<div className="info-side">
				<h3>America is Great</h3>
				<p>I'm on a mission to visit all 60 of America's National Parks.  Explore an interactive map of my visits, complete with a day by day breakdown and review by park.</p>
				<Link to="/america/" title="National Parks">America's National Parks</Link>
			</div>
		</div>
	);
}

export function About(props){
	return (
		<div className="static-nav-item about-item">
			<aside className="icon-side">
				<i className="fa fa-info"  label="info" />
			</aside>
			<div className="info-side">
				<h3>About</h3>
				<p>Learn about me, learn about how I built this website.  Learn all sorts of neat bits of info.</p>
				<Link to="/about/" title="What Powers This?">About it All</Link>
			</div>
		</div>
	);
}

export function Usage(props){
	return (
		<div className="static-nav-item usage-item">
			<aside className="icon-side">
				<i className="fa fa-id-card-o"  label="usage" />
			</aside>
			<div className="info-side">
				<h3>Usage</h3>
				<p>Do you want to know about these photos?  How can you use them for yourself?  How can you buy them?  Do you just want to tell me I'm awesome?</p>
				<Link to="/usage/" title="How to use">How can I use these photos?</Link>
			</div>
		</div>
	);
}

export function Three60(props){
	return (
		<div className="static-nav-item three-60-item">
			<aside className="icon-side">
				<i className="fa fa-bullseye"  label="three-60" />
			</aside>
			<div className="info-side">
				<h3>360°</h3>
				<p>360° photos and video are awesome.  Check out some of mine!</p>
				<Link to="/360/" title="360° Media">360° Media!</Link>
			</div>
		</div>
	);
}

export function Time(props){
	return (
		<div className="static-nav-item time-lapse">
			<aside className="icon-side">
				<i className="fa fa-paper-plane-o" label="time-lapse" />
			</aside>
			<div className="info-side">
				<h3>Time-Lapses</h3>
				<p>I've always liked watching time-lapse videos.  It turns out that making them isn't as easy as it looks.</p>
				<Link to="/timelapse/" title="Time-Lapse Videos">Time-Lapse Videos</Link>
			</div>
		</div>
	);
}

export function NewZel(props){
	return (
		<div className="static-nav-item new-zealand">
			<aside className="icon-side">
				<img src="https://static.alphavega.com/pngs/NewZealand.png" title="Map of New Zealand" alt="Map of New Zealand" />
			</aside>
			<div className="info-side">
				<h3>New Zealand</h3>
				<p>For our Honeymoon, Maryellen and I went to New Zealand.  It was one of the most unique and greatest trips we'd ever taken.</p>
				<Link to="/trip/New Zealand/" title="New Zealand">New Zealand Honeymoon</Link>
			</div>
		</div>
	);
}
import React from "react";
import ImgCaption from "../utils/ImgCaption";
import { Link } from "react-router-dom";
import { buildTimeURL } from "../utils/Normalizers";

export default class TimeThumb extends React.Component {

	render(){
		var { props } = this;

		return (
			<Link to={`/timelapse/${props.name}/`} className="time-thumb-link" title="View this Timelapse">
				<figure className="time-thumb">
					<img src={buildTimeURL(props.file_name, "photo")} width="300" height="300" alt={props.name} />
					<ImgCaption {...props} />
				</figure>
			</Link>
		);
	}

}
import React from "react";
import { connect } from "react-redux";

import { loadCollection, loadAlbums } from "../store/reducers/Collection";

import Header from "../album/Header";
import AlbumItem from "./AlbumItem";
import CollectionItem from "./CollectionItem";

export class Collection extends React.Component {

	static displayName = "Collection";

	static defaultProps = {
		albumList:[],
		focusPhoto:null,
	};

	componentWillMount(){
		var { props } = this;

		props.loadCollection(props.match.params.collection);
	}

	componentDidUpdate(prevProps){
		var currentProps = this.props;

		if(prevProps.match.params.collection !== currentProps.match.params.collection){
			currentProps.loadCollection(currentProps.match.params.collection);
		}
	}
	
	render(){
		var { props } = this;
		return (
			<article className="the-collection">
				<Header type="collection" focusPhoto={props.focusPhoto} />
				<div className="album-list">
					{props.albumList.map(v => {
						if(v.type === "album"){
							return <AlbumItem key={v.id} {...v} />;
						}
						return <CollectionItem key={`${v.id}-collection`} {...v} />;
					})}
				</div>
			</article>
		);
	}

}

function mapStateToProps(state, ownProps){
	let focusPhoto = {}
		, albumList = state.collection.albums || []
		, al = 0
		, len = state.collection.albums.length || 0
		;

	for(al; al < len; al++){
		if(albumList[al] && albumList[al].photos && albumList[al].photos[0]){
			focusPhoto = albumList[al].photos[0];
			break;
		}
	}	//end for(al < len)
	return {
		albumList:state.collection.albums,
		focusPhoto,
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		loadCollection(collectionName){
			dispatch(loadCollection(collectionName));
			return dispatch(loadAlbums(collectionName));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
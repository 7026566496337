import { ReduceAll } from "./ReduceAll";

import { parsePhotos } from "utils/Normalizers";

export const ALBUM_ACTIONS = {
	LOAD_ALBUM_AJAX:"LOAD_ALBUM_AJAX",
	LOAD_ALBUM_SUCCESS:"LOAD_ALBUM_SUCCESS",
	LOAD_PARK_DETAILS_ERROR:"LOAD_PARK_DETAILS_ERROR",

	LOAD_PHOTOS_AJAX:"LOAD_PHOTOS_AJAX",
	LOAD_PHOTOS_SUCCESS:"LOAD_PHOTOS_SUCCESS",
};

export function loadAlbum(album){
	return (dispatch, getState) => {
		var currentAlbum = getState().album;

		if(typeof(album) === "number"){
			if(currentAlbum.info && currentAlbum.info.id === album){
				return;
			}
		} else if(currentAlbum.info && currentAlbum.info.long_name && currentAlbum.info.long_name.toLowerCase() === album.toLowerCase()){
			return;
		}

		return dispatch({
			type:ALBUM_ACTIONS.LOAD_ALBUM_AJAX,
			meta:{
				AJAX:{
					success:ALBUM_ACTIONS.LOAD_ALBUM_SUCCESS,
					url:"info.php",
					params:{
						type:"album",
						name:album,
					},
				},
			},
		});
	};
}
export function loadPhotos(album){
	return (dispatch, getState) => {
		var currentAlbum = getState().album;

		if(typeof(album) === "number"){
			if(currentAlbum.info && currentAlbum.info.id === album){
				return;
			}
		} else if(currentAlbum.info && currentAlbum.info.long_name && currentAlbum.info.long_name.toLowerCase() === album.toLowerCase()){
			return;
		}
		
		return dispatch({
			type:ALBUM_ACTIONS.LOAD_PHOTOS_AJAX,
			meta:{
				AJAX:{
					success:ALBUM_ACTIONS.LOAD_PHOTOS_SUCCESS,
					url:"info.php",
					params:{
						type:"album",
						name:album,
						what:"photos"
					},
				},
			},
		});
	}
}

export const IT = {
	isAlbumLoaded:false,
	isPhotosLoaded:false,
	photos:[],
	info:{},
};

const handlers = {
	[ALBUM_ACTIONS.LOAD_ALBUM_AJAX]:setParkLoading,
	[ALBUM_ACTIONS.LOAD_ALBUM_SUCCESS]:initAlbum,
	[ALBUM_ACTIONS.LOAD_PHOTOS_SUCCESS]:initPhotos,
};

function setParkLoading(state, action){
	return {
		...state,
		isAlbumLoaded:false,
	};
}

function initAlbum(state, action){
	return {
		...state,
		info:action.payload,
		isAlbumLoaded:true,
	};
}

function initPhotos(state, action){
	return {
		...state,
		isPhotosLoaded:true,
		photos:action.payload.data.map(parsePhotos),
		randomPhoto:Math.floor(Math.random() * action.payload.data.length),
	};
}

export default ReduceAll(IT, handlers);

import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import footReducer from "./reducers/Foot";
import homeReducer from "./reducers/Home";
import navReducer from "./reducers/Nav";
import albumReducer from "./reducers/Album";
import collectionReducer from "./reducers/Collection";
import photoReducer from "./reducers/Photo";
import parkReducer from "./reducers/Park";
import mapReducer from "./reducers/Map";
import usaReducer from "./reducers/USA";
import threeReducer from "./reducers/Three60";

function makeRootReducer(asyncReducers){
	return combineReducers({
		// External Reducers
		routing: routerReducer,

		home:homeReducer,
		nav:navReducer,
		album:albumReducer,
		collection:collectionReducer,
		photo:photoReducer,
		park:parkReducer,
		map:mapReducer,
		usa:usaReducer,
		three60:threeReducer,
		footer:footReducer,
	});
}

export default makeRootReducer;
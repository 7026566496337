import { ReduceAll } from "./ReduceAll";
// import { format, parse } from "date-fns";

// import { DIMS_URL } from "../../utils/common";

export const COLLECTION_ACTIONS = {
	LOAD_COLLECTION_AJAX:"LOAD_COLLECTION_AJAX",
	LOAD_COLLECTION_SUCCESS:"LOAD_COLLECTION_SUCCESS",
	LOAD_PARK_DETAILS_ERROR:"LOAD_PARK_DETAILS_ERROR",

	LOAD_COLLECTION_ALBUMS:"LOAD_COLLECTION_ALBUMS",
	LOAD_COLLECTION_ALBUMS_SUCCESS:"LOAD_COLLECTION_ALBUMS_SUCCESS",
};

export function loadCollection(collection){
	return (dispatch, getState) => {
		var currentCol = getState().collection;

		if(currentCol
			&& currentCol.info
			&& currentCol.info.long_name
			&& currentCol.info.long_name.toLowerCase() === collection){
			return;
		}

		return dispatch({
			type:COLLECTION_ACTIONS.LOAD_COLLECTION_AJAX,
			meta:{
				AJAX:{
					success:COLLECTION_ACTIONS.LOAD_COLLECTION_SUCCESS,
					url:"info.php",
					params:{
						type:"collection",
						name:collection,
					},
				},
			},
		});
	};
}
export function loadAlbums(collection){
	return (dispatch, getState) => {
		var currentCol = getState().collection;
		
		if(currentCol
			&& currentCol.info
			&& currentCol.info.long_name
			&& currentCol.info.long_name.toLowerCase() === collection
			&& currentCol.albums.length){
			return;
		}

		return dispatch({
			type:COLLECTION_ACTIONS.LOAD_COLLECTION_ALBUMS,
			meta:{
				AJAX:{
					success:COLLECTION_ACTIONS.LOAD_COLLECTION_ALBUMS_SUCCESS,
					url:"info.php",
					params:{
						type:"collection",
						name:collection,
						what:"albums"
					},
				},
			},
		});
	}
}

export const IT = {
	isCollectionLoaded:false,
	isAlbumsLoaded:false,
	albums:[],
	info:{},
};

const handlers = {
	[COLLECTION_ACTIONS.LOAD_COLLECTION_AJAX]:setIsLoading,
	[COLLECTION_ACTIONS.LOAD_COLLECTION_SUCCESS]:initCollection,
	[COLLECTION_ACTIONS.LOAD_COLLECTION_ALBUMS_SUCCESS]:initAlbums,
};

function setIsLoading(state, action){
	return {
		...state,
		isCollectionLoaded:false,
	};
}

export function initCollection(state, action){
	var col = action.payload[0]
		, info = {
			long_name:col.collection_name,
			name:col.collection_name,
			id:col.collection_id,
			desc:col.collection_desc,
			total_albums:col.total_albums,
			total_photos:col.total_photos,
		}

	return {
		...state,
		info,
		isCollectionLoaded:true,
	};
}

function initAlbums(state, action){
	return {
		...state,
		isAlbumsLoaded:true,
		albums:action.payload,
	}
}

export default ReduceAll(IT, handlers);

import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Helmet from "react-helmet";

import NextPhoto from "../utils/NextPhoto";
import TimeVideo from "./TimeVideo";
import "./TimelapseFocus.css";

export class TimelapseFocus extends React.Component {

	static defaultProps = { photoName:"", currentPhotoIdx:-1 };

	state = { nextPhoto:null, photoIdx:-1, };

	nextPhoto = (evt) => {
		evt.preventDefault();
		this.setState({ nextPhoto:true, photoIdx:this.props.currentPhotoIdx + 1 });
	}
	prevPhoto = (evt) => {
		evt.preventDefault();
		this.setState({ nextPhoto:true, photoIdx:this.props.currentPhotoIdx - 1 });	
	}

	render(){
		var { props, state } = this
			, $video = null
			, $help = null
			, nextDisabled = false
			, prevDisabled = false
			;

		if(state.nextPhoto){ return <Redirect to={`/time-lapse/${props.photoList[state.photoIdx].name}/}`} />; }

		if(props.photoList.length > 0 && props.currentPhotoIdx !== -1){
			$video = <TimeVideo {...props.photoList[props.currentPhotoIdx]} />;
			$help = <Helmet title={`${props.photoList[props.currentPhotoIdx].name} - Time-Lapse`} />
		}

		if(props.currentPhotoIdx === 0){
			prevDisabled = true;
		}
		if(props.currentPhotoIdx === (props.photoList.length -1)){
			nextDisabled = true;
		}

		return (
			<article className="time-lapse-focused">
				{$help}
				<NextPhoto onClick={this.prevPhoto} type="previous" isDisabled={prevDisabled} title="Previous Timelapse" />
				{$video}
				<NextPhoto onClick={this.nextPhoto} isDisabled={nextDisabled} title="Next Timelapse" />
			</article>
		);
	}

}


function mapStateToProps(state, ownProps){
	var rg = new RegExp(ownProps.photoName, "gi");

	var currentPhotoIdx = state.album.photos.findIndex(v => {
		return rg.test(v.name);
	});

	return {
		photoList:state.album.photos,
		currentPhotoIdx,
	};
}

export default connect(mapStateToProps, null)(TimelapseFocus);

import React from "react";
import ImgCaption from "../utils/ImgCaption";
import { buildTimeURL } from "../utils/Normalizers";

export default class TimeVideo extends React.Component {

	_trackEvent = (evt) => {
		evt.persist();
		console.log("Tracking what", evt);
	}

	render(){
		var { props } = this;

		return (
			<figure className="video-figure"
				itemScope
				itemType="https://schema.org/VideoObject"
				itemProp="Video">
				<video controls loop
					className="time-video"
					width="400"
					poster={buildTimeURL(props.file_name, "photo")}
					onPlay={this._trackEvent}
					onPause={this._trackEvent}
					onEnded={this._trackEvent}>
					<source src={buildTimeURL(props.file_name)} />
				</video>
				<ImgCaption {...props} />
			</figure>
		);
	}
}
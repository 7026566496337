import React from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
// import { parse, format } from "date-fns";

import { loadPhotoInfo, loadExif } from "../store/reducers/Photo";

import AlbumItem from "../collection/AlbumItem";
import ParkInfo from "./ParkInfo";
import EXIF from "./EXIF";
import "./Photo.css";

export class Photo extends React.Component {

	static displayName = "Photo";

	static defaultProps = {
		file_name:"",
		name:"",
		desc:"",
		id:-1,
		albums:[],
		nps:null,
		exif:null,
		isPhotoLoaded:false,
	};

	componentWillMount(){
		var { props } = this;

		props.loadPhotoInfo(props.match.params.photo)
			.then((resp) => {
				if(!resp.success){ return; }
				var { payload } = resp;

				if(payload.exif){ return; }

				props.loadExif(payload.info.file_name);
			});
	}

	render(){
		var { props } = this
			, aPhoto = {
				file_name:props.file_name,
				name:props.name,
				desc:props.desc,
				id:props.id,
			}
			;

		return (
			<article className="photo-details" itemScope itemType="http://schema.org/Photo">
				<figure className="main-photo">
					<img src={`https://steve.deadlycomputer.com/dims/large/${props.file_name}`} alt={props.name} itemProp="photo" />
					<figcaption className="caption">
						<h1 itemProp="name">{props.name}</h1>
						<p itemProp="description">{props.desc}</p>
						<EXIF {...props.exif} takenOn={props.taken} />
					</figcaption>
				</figure>
				<aside className="photo-album-list">
					<p>This photo can be seen in the following albums:</p>
					<section className="album-list">
						{props.albums.map(v => {
							return <AlbumItem name={v.long_name} photos={[aPhoto]} key={v.id} />;
						})}
					</section>
				</aside>
				<ParkInfo {...props.nps} />
			</article>
		);
	}

}

function mapStateToProps(state){
	var { currentPhoto } = state.photo;
	return {
		isPhotoLoaded:state.photo.isPhotoLoaded,
		albums:currentPhoto.albums,
		nps:currentPhoto.nps,
		exif:currentPhoto.exif,
		...currentPhoto.info,
	};
}

function mapDispatchToProps(dispatch){
	return {
		loadPhotoInfo(photoId){
			return dispatch(loadPhotoInfo(photoId));
		},
		loadExif(photo){
			return dispatch(loadExif(photo));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Photo);
import React from "react";
import { connect } from "react-redux";

import { getAllVisitActivities, getTodayActs } from "../selectors/Park";
import Activity from "../nps/Activity";
import "../nps/Activity.css"

// import "../nps/Park.css";
// import "../nps/Responsive.Park.css";
import "./AllActivities.css";

export class AllActivities extends React.Component {

	static displayName = "AllActivities";

	static defaultProps = {
		activities:[],
		currentDay:{},
	};

	render(){
		var { activities, currentDay } = this.props;

		return (
			<aside className="all-activity-list">
				<div className="day-activities">
					<p>These are just some of the activities you can do here.  The clear ones are things we did today.</p>
					<div className="day-activity-list">
						{activities.map((aa) => {
							let doneToday = false;
							if(currentDay[aa.id]){
								doneToday = true
							}
							return <Activity key={aa.name} {...aa} isHighlighted={doneToday} />
						})}
					</div>
				</div>
			</aside>
		);
	}

}

function mapStateToProps(state){
	var activities = getAllVisitActivities(state)
		, currentDay = getTodayActs(state)
		;

	return {
		activities,
		currentDay
	};
}


export default connect(mapStateToProps, null)(AllActivities);
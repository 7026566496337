import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import Helmet from "react-helmet";

import Nav from "./nav/Nav";
import Yosemite from "./Yosemite";
import Footer from "./footer/Footer";
import './App.css';

class App extends Component {

	static defaultProps = {
		store:null,
	};

	static propTypes = {
		store:PropTypes.object.isRequired,
	};

	render() {
		return (
			<Provider store={this.props.store}>
				<div className="App">
					<Helmet
						titleTemplate = "%s :: Stephen Giorgi"
						defaultTitle  = "Photo Gallery :: Stephen Giorgi" />
					<Nav />
					<Yosemite />
					<Footer />
				</div>
			</Provider>
		);
	}
}

export default App;

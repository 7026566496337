import React from "react";
import { Author, Copyright } from "../utils/common";
import Micro from "../utils/Micro";
import "./ImgCaption.css";


const THUMBS = {
	timelapse(p){
		return `https://steve.deadlycomputer.com/dims/time/p/${p.file_name.replace(/mp4/gi,"jpg")}`;
	},
	three(p){
		return `https://steve.deadlycomputer.com/dims/360/t/${p.file_name}`;
	},
}

export default function ImgCaption(props){
	var thumb = THUMBS[props.type](props)
		, mc = [Author, Copyright
		, { itemProp:"width", content:props.width, }
		, { itemProp:"height", content:props.height }
		, { itemProp:"uploadDate", content:props.taken }
		, { itemProp:"thumbnailUrl", content:thumb }
		];

	return (
		<figcaption className="img-caption swish-title">
			<Micro micro={mc} />
			<h5 className="title" itemProp="name">{props.name}</h5>
			<div className="desc" itemProp="description">{props.desc}</div>
		</figcaption>
	);
}

ImgCaption.defaultProps = {
	type:"timelapse",
	width:1776,
	height:704,
	taken:"1776-0-04",
	name:"Fourth of July",
	desc:"Independence Day",
	file_name:"",
};
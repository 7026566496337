import React from "react";

import { DIMS_URL } from "../utils/common";
import "./ImageItem.css";

export default class ImageItem extends React.Component {

	static displayName = "ImageItem";

	render(){
		var { props } = this
			, photoInfo = props.photo
			, styles = {
				margin:props.margin,
				height:photoInfo.height,
				width:photoInfo.width,
			}
			;

		return (
			<figure
				className="image"
				style={styles}
				onClick={(evt) => {props.onClick(evt, { index:props.index, photo:photoInfo })}}>
				<img
					src={`${DIMS_URL}large/${photoInfo.file_name}`}
					alt={photoInfo.name}
				/>
			</figure>
		);
	}

}
import React from "react";
import { Link } from "react-router-dom";

import PhotoStack from "./PhotoStack";
import Micro from "../utils/Micro";
import { Author, License } from "../utils/common";
import "./AlbumItem.css";

export default class AlbumItem extends React.Component {

	static displayName = "AlbumItem";

	static defaultProps = {
		name:"",
		desc:"",
		photos:[],
		totalPhotos:0,
	}

	render(){
		var { props } = this
			, micro = [Author
				, License
				, { itemProp:"name", content:props.name }
				, { itemProp:"url", content:`https://steve.deadlycomputer.com/album/${props.name}/` }
			]
			;

		return (
			<section className="album-item" itemScope itemType="https://schema.org/CreativeWork">
				<header className="item-header">
					<h4 className="collection-name">
						<Link to={`/album/${props.name}/`}>{props.name}</Link>
					</h4>
					<p itemProp="description">{props.desc}</p>
				</header>
				<aside className="item-photos">
					<PhotoStack photos={props.photos} wrapInLink link={`/album/${props.name}/`} />
				</aside>
				<Micro micro={micro} />
			</section>
		);
	}
}

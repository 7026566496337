import React from "react"
import PropTypes from "prop-types";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";


import Home from "./home/Home";
import Park from "./nps/Park";
import Album from "./album/Album";
import Collection from "./collection/Collection";
import USA from "./america/USA";
import Usage from "./static/Usage";
import About from "./static/About";
import Photo360 from "./pano/Photo360";
import Photo from "./photo/Photo";
import Timelapse from "./time/Timelapse";
import Four04 from "./static/Four04";
import NewZealand from "./trip/NewZealand";

import { toggleNav } from "./store/reducers/Nav";
import { trackPage } from "./utils/Tracking";

export class Yosemite extends React.Component {

	static displayName = "Yosemite";

	static contextTypes = {
		store:PropTypes.object.isRequired,
	};

	componentWillMount(){
		var { context, props  } = this;

		props.history.listen(function(location){
			context.store.dispatch(toggleNav(false));
			trackPage(location.pathname);
			try {
				window.scrollTo(0,0);
			} catch (err){}
		});
	}

	render(){
		return (
			<main className="main-container">
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/park/:park/" component={Park} />
					<Route path="/album/:album/" component={Album} />
					<Route path="/collection/:collection/" component={Collection} />
					<Route path="/america/" component={USA} />
					<Redirect from="/use/" to="/usage/" />
					<Route path="/photo/:photo/" component={Photo} />
					<Route path="/usage/" component={Usage} />
					<Route path="/about/" component={About} />
					<Route path="/360/:photo?/" component={Photo360} />
					<Redirect from="/time-lapse/:scene?/" to="/timelapse/:scene?/" />
					<Route path="/timelapse/:scene?/" component={Timelapse} />
					<Redirect from="/trip/New-Zealand/" to="/trip/New Zealand/" />
					<Route path="/trip/New Zealand/" component={NewZealand} />
					<Route component={Four04} />
				</Switch>
			</main>
		);
	}
}

export default withRouter(Yosemite);

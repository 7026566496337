import React from "react";
import { Link } from "react-router-dom";

import "./ParkMarker.css";

export default function ParkMarker(props){
	var $info = null
		, $header = <h3>{props.name}</h3>
		, $review = null
		, hasVisited = ""
		;

	if(props.haveVisited){
		hasVisited = "has-visited";
		$header = <Link to={`/park/${props.name}/`} title="Read more about my trip">{props.name}</Link>;
		// $review = <p>{props.review}</p>;
	}
	if(props.photo && props.photo.file_name){
		$info = (
			<figure className="map-marker-photo">
				<img src={`//steve.deadlycomputer.com/dims/large/${props.photo.file_name}`} alt={props.photo.name} />
				<figcaption>{props.photo.name}</figcaption>
			</figure>
		);
	}
	return (
		<div className={`park-marker ${hasVisited}`}>
			{$header}
			{$review}
			{$info}
		</div>
	);
}
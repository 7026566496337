/**
 * 404 File
 * This is the Information for the Usage section of the site
 * It contains the basic Usage information for the Photos
 */
import React		from 'react';
// import Helmet		from 'react-helmet';
import { Link } from "react-router-dom";

import { trackEvent } from "../utils/Tracking";
import "./Four04.css";

export default class Four04 extends React.Component {

	componentDidMount(){
		trackEvent("404", "404");
	}

	render(){
		// var { props } = this;

		return (
			<section>
				<div className="four-0-4-back">
					<h1 className="lost-header">You're Lost</h1>
					<div className="back-home">
						<Link to="/">Let's try going back home</Link>
					</div>
				</div>
			</section>
		);
	}
}